import raportReducer from "./raportReducer"
import asigurariReducer from "./asigurariReducer"
import authReducer from "./authReducer"
import errorReducer from "./errorReducer"
import setariReducer from "./setariReducer"
import angajatiReducer from "./angajatiReducer"
import uleiReducer from "./uleiReducer"

import { combineReducers } from "redux"

const allReducers = combineReducers({
  raportReducer,
  asigurariReducer,
  authReducer,
  errorReducer,
  setariReducer,
  angajatiReducer,
  uleiReducer,
})

export default allReducers

import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import ReactToPrint from "react-to-print"
import "../../Print.css"

export default function RaportHeader(props) {
  const setari = useSelector((state) => state.setariReducer)
  const dispatch = useDispatch()
  const raport = useSelector((state) => state.uleiReducer)

  function schimbaDimensiuniMasini(e) {
    let element = document.getElementById(e.target.id)
    let pozitia = element.getBoundingClientRect()
    function schimbaPos(e) {
      let width = setari.dimensiuniUlei[element.id]
      let nouWidth = e.clientX - pozitia.right
      //width e in pixeli si trebuie sa fac din setari cu dispatch totu oricum

      //da ceva gen 2.2415 si se pune ca mii adica 2415
      let widthNou = Math.floor(nouWidth) + Math.floor(width)
      if (widthNou < 100) widthNou = 100
      // if (widthNou > 2000) widthNou = 2000
      let setariCopy = setari
      setariCopy.dimensiuniUlei[element.id] = widthNou
      dispatch({
        type: "SCHIMBA_SETARI",
        payload: setariCopy,
      })
      props.updateaza()
      window.removeEventListener("mouseup", schimbaPos)
    }

    window.addEventListener("mouseup", schimbaPos)
  }
  return (
    <div className="ContainerHeader">
      <div style={{ width: setari.dimensiuniUlei.serie + "px" }}>
        <span
          id="serie"
          onMouseDown={schimbaDimensiuniMasini}
          className="raportHeaderBara"
        ></span>
        Serie
      </div>

      <div style={{ width: setari.dimensiuniUlei.piesa + "px" }}>
        <span
          id="piesa"
          onMouseDown={schimbaDimensiuniMasini}
          className="raportHeaderBara"
        ></span>
        Piesă schimbată
      </div>

      <div style={{ width: setari.dimensiuniUlei.schimb + "px" }}>
        <span
          id="schimb"
          onMouseDown={schimbaDimensiuniMasini}
          className="raportHeaderBara"
        ></span>
        Schimb ulei
      </div>

      <div style={{ width: setari.dimensiuniUlei.km + "px" }}>
        <span
          id="km"
          onMouseDown={schimbaDimensiuniMasini}
          className="raportHeaderBara"
        ></span>
        Km
      </div>

      <div style={{ width: setari.dimensiuniUlei.obs + "px" }}>
        <span
          id="obs"
          onMouseDown={schimbaDimensiuniMasini}
          className="raportHeaderBara"
        ></span>
        Observații
      </div>
      <div style={{ display: "none" }}>
        {raport !== null ? <DePrintat></DePrintat> : ""}
      </div>
    </div>
  )
}

function DocumentPrint(props) {
  const raport = useSelector((state) => state.uleiReducer)

  return (
    <div className="dimensiuni">
      <table>
        <thead>
          <th>column 1</th>
          <th>column 2</th>
          <th>column 3</th>
        </thead>
        <tbody>
          {raport.row.map((item, index) => {
            return (
              <tr>
                <td>{item.serie}</td>
                <td>{item.piesa}</td>
                <td>{item.schimb}</td>
                <td>{item.km}</td>
                <td>{item.serie}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

class DePrintat extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => <button>Print this out!</button>}
          content={() => this.componentRef}
        />
        <div className="ContainerPrintButon">
          <DocumentPrint ref={(el) => (this.componentRef = el)} />
        </div>
      </div>
    )
  }
}

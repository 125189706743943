import React, { useEffect } from "react"
import PersonalCard from "./PersonalCard.js"

export default function PersonalMap(props) {
  useEffect(() => {
    //console.log(props)
  }, [])
  const Personal = props.date.map((item, index) => {
    return (
      <PersonalCard
        click={props.editeaza}
        key={index}
        date={item.angajat}
        index={index}
        id={item._id}
      ></PersonalCard>
    )
  })
  return <div>{Personal}</div>
}

import React from "react"

export default function (props) {
  return (
    <div className="AsigurariHeader">
      <div style={{ width: props.dimensiuni.serieMasina }}>Serie mașină</div>

      <div style={{ width: props.dimensiuni.RCA }}>RCA</div>

      <div style={{ width: props.dimensiuni.ITP }}>ITP</div>

      <div style={{ width: props.dimensiuni.ROVINIETA }}>Rovinietă</div>

      <div style={{ width: props.dimensiuni.COPIECONFORMA }}>
        Copie Conformă
      </div>
      <div style={{ width: props.dimensiuni.KM }}>Km schimb ulei</div>
    </div>
  )
}

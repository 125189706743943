import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

export default function RaportHeader(props) {
  const setari = useSelector((state) => state.setariReducer)
  const dispatch = useDispatch()
  function schimbaDimensiuniMasini(e) {
    let element = document.getElementById(e.target.id)
    let pozitia = element.getBoundingClientRect()
    function schimbaPos(e) {
      let width = setari.dimensiuni[element.id]
      let nouWidth = e.clientX - pozitia.right
      //width e in pixeli si trebuie sa fac din setari cu dispatch totu oricum

      //da ceva gen 2.2415 si se pune ca mii adica 2415
      let widthNou = Math.floor(nouWidth) + Math.floor(width)
      if (widthNou < 100) widthNou = 100
      // if (widthNou > 2000) widthNou = 2000
      let setariCopy = setari
      setariCopy.dimensiuni[element.id] = widthNou
      dispatch({
        type: "SCHIMBA_SETARI",
        payload: setariCopy,
      })
      props.updateaza()
      window.removeEventListener("mouseup", schimbaPos)
    }

    window.addEventListener("mouseup", schimbaPos)
  }
  return (
    <div className="ContainerHeader">
      <div style={{ width: setari.dimensiuni.masini + "px" }}>
        <span
          id="masini"
          onMouseDown={schimbaDimensiuniMasini}
          className="raportHeaderBara"
        ></span>
        Serie
      </div>
      <div style={{ width: setari.dimensiuni.programStabilit + "px" }}>
        <span
          id="programStabilit"
          onMouseDown={schimbaDimensiuniMasini}
          className="raportHeaderBara"
        ></span>{" "}
        Program stabilit
      </div>
      <div style={{ width: setari.dimensiuni.beneficiar + "px" }}>
        <span
          id="beneficiar"
          onMouseDown={schimbaDimensiuniMasini}
          className="raportHeaderBara"
        ></span>{" "}
        Beneficiar
      </div>
      <div style={{ width: setari.dimensiuni.programRelativ + "px" }}>
        <span
          id="programRelativ"
          onMouseDown={schimbaDimensiuniMasini}
          className="raportHeaderBara"
        ></span>
        Program relativ
      </div>
      <div style={{ width: setari.dimensiuni.rest + "px" }}>
        <span
          id="rest"
          onMouseDown={schimbaDimensiuniMasini}
          className="raportHeaderBara"
        ></span>
        Rest
      </div>
      <div style={{ width: setari.dimensiuni.realizari + "px" }}>
        <span
          id="realizari"
          onMouseDown={schimbaDimensiuniMasini}
          className="raportHeaderBara"
        ></span>
        Realizari
      </div>
      <div style={{ width: setari.dimensiuni.numeSofer + "px" }}>
        <span
          id="numeSofer"
          onMouseDown={schimbaDimensiuniMasini}
          className="raportHeaderBara"
        ></span>
        Nume sofer
      </div>
    </div>
  )
}

import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { login } from "./Actions/auth.js"
import axios from "axios"
import { useHistory } from "react-router-dom"
import LinkConfig from "./Componente/Config.js"

export default function Login(props) {
  const [state, setState] = useState({
    token: "",
    name: "",
    password: "",
    email: "",
  })

  //const raport = useSelector((state) => state.raportReducer)
  const dispatch = useDispatch()
  // dispatch(schimbaAsigurari(res.data.asigurari))
  const history = useHistory()
  const loginData = useSelector((state) => state.authReducer)
  function onChange(e) {
    let st = state
    st[e.target.id] = e.target.value
    setState(st)
  }

  const [erori, setErori] = useState("")

  function login() {
    //console.log("AM APASAT LOGIN", loginData)
    let st = state
    axios
      .post(LinkConfig.link + "/users/login", st)
      .then((res) => {
        //console.log("intra aici login", res)
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: res.data,
        })
        history.push("/")
      })
      .catch((err) => {
        console.log("ERORI", err.response.data.msg)
        dispatch({
          type: "LOGIN_FAIL",
        })
        setErori(err.response.data.msg)
      })
  }
  function onKeyUp(e) {
    if (e.charCode === 13) {
      login()
    }
  }
  return (
    <div className="ContainerLogin" onKeyPress={onKeyUp}>
      <div className="ContainerLoginMic">
        <img
          src={require("./Imagini/siglaFirma.png")}
          label="Sigla firma"
        ></img>
        <div>Autentificare:</div>
        <div className="loginEroare">{erori}</div>
        <div>
          <input
            id="email"
            onChange={onChange}
            type="email"
            placeholder="E-mail"
          ></input>
        </div>
        <div className="LoginParola">
          <input id="password" onChange={onChange} type="password"></input>
        </div>
        <div onClick={login} className="loginButton">
          LOGIN
        </div>
      </div>
    </div>
  )
}

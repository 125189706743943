import React, { useState, useEffect } from "react"
import RaportHeader from "./RaportHeader"
import RaportRow from "./RaportRow"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import axios from "axios"
import {
  faCheck,
  faPlus,
  faSignOutAlt,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSelector, useDispatch } from "react-redux"
import { schimba, schimbaAsigurari, schimbaAngajati } from "../../Actions"
import LinkConfig from "../Config.js"
import MediaQuery from "react-responsive"
import { DocumentProvider } from "mongoose"

let updatedAtAsigurari = new Date()
export default function Raport() {
  const loginData = useSelector((state) => state.authReducer)
  const [update, setUpdate] = useState(-1)
  const asigurari = useSelector((state) => state.asigurariReducer)
  const raport = useSelector((state) => state.raportReducer)
  const dispatch = useDispatch()
  const [data, setData] = useState(new Date())
  const [editeaza, setEditeaza] = useState(false)
  const [loading, setLoading] = useState(false)
  const angajati = useSelector((state) => state.angajatiReducer)
  const [arataCalendarResponsiv, setArataCalendarResponsiv] = useState(false)
  function updateaza() {
    setUpdate(update + 1)
  }

  useEffect(() => {
    //preiau toti angajatii
    axios
      .get(LinkConfig.link + "/angajati/", {
        headers: { Authorization: loginData.token },
      })
      .then((res) => {
        dispatch(schimbaAngajati(res.data))
        //console.log("Aici sunt angajatii", res.data.asigurari)
      })
      .catch((err) => console.log(err))

    //preiau raportu pe ziua de azi
    axios
      .get(LinkConfig.link + "/asigurari/preia/5f995c38f01ebf1570f70d12", {
        headers: { Authorization: loginData.token },
      })
      .then(async (res) => {
        updatedAtAsigurari = res.data.updatedAt
        // console.log("ASIGURARI AICIIIIIIIIIIIIIIIIIIIIII", res.data.updatedAt)
        dispatch(schimbaAsigurari(res.data.asigurari))
      })
    onChange(new Date())
  }, [])

  function inchideCalendar() {
    setArataCalendarResponsiv(false)
    console.log("arata")
  }

  function onChange(dataAzi) {
    // console.log("TOKEN", loginData.token)
    let valoare = dataAzi
    valoare.setMilliseconds(0)
    valoare.setSeconds(0)
    valoare.setMinutes(0)
    valoare.setHours(15)
    setData(valoare)
    setLoading(true)
    axios
      .get(LinkConfig.link + "/raportZi/exista/" + valoare, {
        headers: { Authorization: loginData.token },
      })
      .then((res) => {
        if (res.request.response === "[]") {
          axios
            .get(
              LinkConfig.link + "/asigurari/preia/5f995c38f01ebf1570f70d12",
              {
                headers: { Authorization: loginData.token },
              }
            )
            .then((res) => {
              dispatch(schimbaAsigurari(res.data.asigurari))
              let asig = []
              let asigLength = res.data.asigurari.length //pentru performanta
              for (let i = 0; i < asigLength; i++) {
                asig.push({
                  serieMasina: res.data.asigurari[i].serieMasina,
                  personal: [],
                })
              }

              axios
                .post(
                  LinkConfig.link + "/raportZi/add",

                  {
                    row: asig,
                    ultimulEditat: "George",
                    date: valoare,
                  },
                  {
                    headers: { Authorization: loginData.token },
                  }
                )
                .then((res) => {
                  // console.log("intru in raport nou", res.data)

                  //iau raportu dupa ce l-am pus
                  axios
                    .get(LinkConfig.link + "/raportZi/exista/" + valoare, {
                      headers: { Authorization: loginData.token },
                    })
                    .then((resss) => {
                      //console.log("AICII UNDE E BINE INTRA", resss.data)
                      dispatch(schimba(resss.data[0]))

                      setLoading(false)
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                })
                .catch((err) => {
                  console.log(err)
                })
            })
        } else {
          if (res.data[0].createdAt < updatedAtAsigurari) {
            let dataOld = res.data[0]
            axios
              .get(
                LinkConfig.link + "/asigurari/preia/5f995c38f01ebf1570f70d12",
                {
                  headers: { Authorization: loginData.token },
                }
              )
              .then((ress) => {
                let dataOldLength = dataOld.row.length
                let asigLength = ress.data.asigurari.length //pentru performanta
                // console.log("intra aici si nu e bine deloc")
                for (let i = 0; i < dataOldLength; i++) {
                  let sw = false
                  for (let j = 0; j < asigLength; j++) {
                    if (
                      dataOld.row[i].serieMasina ===
                      ress.data.asigurari[j].serieMasina
                    ) {
                      sw = true
                      continue
                    }
                  }
                  if (
                    !sw &&
                    !(
                      (dataOld.row[i].programStabiliti !== undefined &&
                        dataOld.row[i].programStabiliti.length !== 0) ||
                      (dataOld.row[i].beneficiar !== undefined &&
                        dataOld.row[i].beneficiar.length !== 0) ||
                      (dataOld.row[i].programRelativ !== undefined &&
                        dataOld.row[i].programRelativ.length !== 0) ||
                      (dataOld.row[i].rest !== undefined &&
                        dataOld.row[i].rest.length !== 0) ||
                      (dataOld.row[i].realizari !== undefined &&
                        dataOld.row[i].realizari.length !== 0) ||
                      (dataOld.row[i].numeSofer !== undefined &&
                        dataOld.row[i].numeSofer.length !== 0)
                    )
                  ) {
                    dataOld.row.splice(i, 1)
                    i--
                    dataOldLength--
                  }
                }
                asigLength = ress.data.asigurari.length
                dataOldLength = dataOld.row.length //pentru performanta
                for (let i = 0; i < asigLength; i++) {
                  let sw = false
                  for (let j = 0; j < dataOldLength; j++) {
                    if (
                      ress.data.asigurari[i].serieMasina ===
                      dataOld.row[j].serieMasina
                    ) {
                      //daca exista deja seria din asigurari in raport atunci treci peste
                      sw = true
                      continue
                    }
                  }
                  if (!sw)
                    dataOld.row.push({
                      serieMasina: ress.data.asigurari[i].serieMasina,
                    })
                }
                dispatch(schimba(dataOld))
                setLoading(false)
              })
          } else {
            dispatch(schimba(res.data[0]))
            setLoading(false)
          }
        }

        setUpdate(update + 1)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function salveza(date) {
    // console.log("INTRU SI SALVEZ AICI")
    let dateRaport = raport
    dateRaport.row = date
    dispatch(schimba(dateRaport))
    axios
      .post(
        LinkConfig.link + "/raportZi/update/" + dateRaport._id,
        {
          row: dateRaport.row,
          ultimulEditat: dateRaport.ultimulEditat,
        },
        {
          headers: { Authorization: loginData.token },
        }
      )
      .then((res) => {
        // console.log("Am Editat  ", dateRaport._id)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function editeazaClick() {
    if (editeaza) {
      setEditeaza(false)
      document
        .getElementById("clickEditeaza")
        .classList.remove("adaugaButonVerde")
    } else {
      setEditeaza(true)
      document.getElementById("clickEditeaza").classList.add("adaugaButonVerde")
    }
  }

  function AdaugaRow() {
    let dateRaport = raport
    dateRaport.row.push({ numeMasina: "true" })
    dispatch(schimba(dateRaport))
    setUpdate(update + 1)
  }
  return (
    <div className="Raport">
      <MediaQuery minDeviceWidth={901}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="ContainerRaport">
            <RaportHeader updateaza={updateaza} />
            {raport === null || loading === true ? (
              <div className="NimicGasit">
                <div>Se incarcă...</div>
                <img src={require("../../Imagini/loading.gif")}></img>
              </div>
            ) : (
              <RaportRow
                date={raport}
                editeaza={editeaza}
                salveaza={salveza}
                inchideCalendar={inchideCalendar}
              ></RaportRow>
            )}
          </div>

          <div className="containerAdaugaButon">
            {editeaza ? (
              <div className="adaugaButon" id="adaugaRow" onClick={AdaugaRow}>
                <div>
                  <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                </div>
                <div className="text">Adauga linie noua</div>
              </div>
            ) : (
              <div></div>
            )}

            {editeaza ? (
              <div
                className="adaugaButon"
                id="clickEditeaza"
                onClick={editeazaClick}
              >
                <div>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div className="text">Salveaza</div>
              </div>
            ) : (
              <div
                className="adaugaButon"
                id="clickEditeaza"
                onClick={editeazaClick}
              >
                <div>
                  <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                </div>
                <div className="text">Editeaza</div>
              </div>
            )}
          </div>
        </div>
        <div className="containerCalendar">
          <Calendar
            style={{ fontSize: "4vh !important" }}
            onChange={onChange}
            className="calendar"
            locale="ro"
          />
        </div>
      </MediaQuery>

      <MediaQuery maxDeviceWidth={900}>
        <div className="ContainerRaport">
          {raport === null || loading === true ? (
            <div className="NimicGasit">
              <div>Se incarcă...</div>
              <img src={require("../../Imagini/loading.gif")}></img>
            </div>
          ) : (
            <RaportRow
              date={raport}
              editeaza={editeaza}
              salveaza={salveza}
              inchideCalendar={inchideCalendar}
            ></RaportRow>
          )}
        </div>

        <div
          className="containerCalendar"
          style={{ bottom: arataCalendarResponsiv ? "15vh" : "-50%" }}
        >
          <Calendar
            style={{ fontSize: "4vh !important" }}
            onChange={onChange}
            className="calendar"
            locale="ro"
          />
        </div>

        <div className="ButoaneJosRaport">
          {editeaza ? (
            <div className="ButonJosRaport" id="adaugaRow" onClick={AdaugaRow}>
              <div>
                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {editeaza ? (
            <div
              className="ButonJosRaport"
              id="clickEditeaza"
              onClick={editeazaClick}
            >
              <div>
                <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
              </div>
            </div>
          ) : (
            <div
              className="ButonJosRaport"
              id="clickEditeaza"
              onClick={editeazaClick}
            >
              <div>
                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
              </div>
            </div>
          )}

          <div className="ButonJosRaport">
            <FontAwesomeIcon
              onClick={() => {
                arataCalendarResponsiv
                  ? setArataCalendarResponsiv(false)
                  : setArataCalendarResponsiv(true)
              }}
              icon={faCalendarAlt}
            ></FontAwesomeIcon>
          </div>
        </div>
      </MediaQuery>
    </div>
  )
}

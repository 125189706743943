import React from "react"
import { useSelector } from "react-redux"

export default function ListaPersonal(props) {
  const angajati = useSelector((state) => state.angajatiReducer)

  function AngajatiComp() {
    let angajatiCopy = angajati
    let angLength = angajatiCopy.length
    let i = 0
    for (i = 0; i < angLength; i++) {
      angajatiCopy[i].angajat.numar = 0
      //console.log("intra AICI", angajatiCopy[i].angajat)
    }

    //console.log("PROPS>INDEX", props.indexAsigurari)
    props.asigurari[props.indexAsigurari].personal.map((item, index) => {
      //console.log("numar", item.numar)
      for (i = 0; i < angLength; i++) {
        if (item.nume === angajatiCopy[i].angajat.nume) {
          //console.log("am intrat unde trebuie")
          angajatiCopy[i].angajat.numar = item.numar
        }
      }
    })
    function compareValues(key, order = "desc") {
      return function innerSort(a, b) {
        if (!a.angajat.hasOwnProperty(key) || !b.angajat.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0
        }

        const varA =
          typeof a.angajat[key] === "string"
            ? a.angajat[key].toUpperCase()
            : a.angajat[key]
        const varB =
          typeof b.angajat[key] === "string"
            ? b.angajat[key].toUpperCase()
            : b.angajat[key]

        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return order === "desc" ? comparison * -1 : comparison
      }
    }
    angajatiCopy.sort(compareValues("numar", "desc"))
    //console.log(angajatiCopy)
    const Angajati = angajatiCopy.map((item, id) => {
      //console.log("AIIIIIIIIIIIIIICI", item.angajat.numar)
      let angNumeCopyUppercase = item.angajat.nume.toUpperCase()
      let x = angNumeCopyUppercase.search(props.date.toUpperCase())
      // console.log(props.date, "item.angajat.nume", item.angajat.nume)
      if (x !== -1)
        return (
          <div key={id} onClick={() => props.click(item.angajat)}>
            {item.angajat.nume}
          </div>
        )
    })

    return Angajati
  }
  return (
    <div contentEditable="false" className="ListaPersonalRaport">
      {AngajatiComp()}
    </div>
  )
}

import React, { useState, useEffect } from "react"
import RaportR from "./RaportR"
import { useSelector } from "react-redux"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

let sw = 0
export default function RaportRow(props) {
  const setari = useSelector((state) => state.setariReducer)
  const [update, setUpdate] = useState(-1)
  const [raport, setRaport] = useState(props.date.row)

  useEffect(() => {
    setUpdate(update + 1)
    //console.log("INTRU UPDATEAZA USEFECT")
  }, [setari.dimensiuniUlei])
  useEffect(() => {
    if (sw === 1) {
      props.inchideCalendar()
    }
  }, [sw])
  useEffect(() => {
    sw = 0
  }, [props])
  useEffect(() => {
    setRaport(props.date.row)
    //console.log("UPDATAT RAPORT ROW", props.date.row[0])
  }, [props.date.row])
  useEffect(() => {
    if (!props.editeaza) props.salveaza(raport)
  }, [props.editeaza])

  function salveazaRow(index, date) {
    let dateRaport = raport
    dateRaport.index = date
    setRaport(dateRaport)
  }

  const Rows = raport.map((item, index) => {
    if (
      (item.piesa !== undefined && item.piesa.length !== 0) ||
      (item.schimb !== undefined && item.schimb.length !== 0) ||
      (item.km !== undefined && item.km.length !== 0) ||
      (item.obs !== undefined && item.obs.length !== 0) ||
      props.editeaza
    ) {
      sw = 1
      return (
        <RaportR
          dimensiuniUlei={setari.dimensiuniUlei}
          key={index}
          date={item}
          index={index}
          editeaza={props.editeaza}
          salveazaRow={salveazaRow}
        ></RaportR>
      )
    }
  })
  return (
    <div>
      {Rows}
      {sw === 0 ? (
        <div className="NimicGasit">
          <div>Introduceți informații</div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

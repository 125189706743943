let initialState = {
  modZi: true,
  modNoapte: false,
  textMarimeRaport: 2,
  textMarimeCalendar: 2,
  afisareNotificariRaport: true,
  timpNotificare: 30,
  numarKmUlei: 10000,
  dimensiuni: {
    masini: "150",
    programStabilit: "170",
    beneficiar: "100",
    programRelativ: "150",
    rest: "100",
    realizari: "100",
    numeSofer: "150",
  },
  dimensiuniUlei: {
    serie: "150",
    piesa: "300",
    schimb: "200",
    km: "150",
    obs: "150",
  },
  culori: {
    background: "rgb(0, 44, 61)",
    backgroundInchis: "rgb(255, 255, 255)",
    backgroundBody: "rgb(245, 245, 245)",
    cardBackground: "rgb(255, 255, 255)",
    textNavbar: "rgb(255, 255, 255)",
    text: "rgb(55, 55, 55)",
    cardText: "rgb(189, 189, 189)",
    boxShadow: "rgb(206, 206, 206)",
  },
}
let initialStateCopy = {}
Object.assign(initialStateCopy, initialState)
if (localStorage.getItem("setari")) {
  initialState = JSON.parse(localStorage.getItem("setari"))

  if (
    initialState.dimensiuniUlei === undefined ||
    initialState.dimensiuniUlei === {}
  ) {
    initialState.dimensiuniUlei = {
      serie: "200",
      piesa: "300",
      schimb: "200",
      km: "150",
      obs: "150",
    }
  } //schimb css
  document.documentElement.style.setProperty(
    "--textMarimeRaport",
    initialState.textMarimeRaport + "vh"
  )
  document.documentElement.style.setProperty(
    "--textMarimeCalendar",
    initialState.textMarimeCalendar + "vh"
  )
  //schimb culori css
  document.documentElement.style.setProperty(
    "--background",
    initialState.culori.background
  )
  document.documentElement.style.setProperty(
    "--backgroundInchis",
    initialState.culori.backgroundInchis
  )
  document.documentElement.style.setProperty(
    "--backgroundBody",
    initialState.culori.backgroundBody
  )
  document.documentElement.style.setProperty(
    "--cardBackground",
    initialState.culori.cardBackground
  )
  document.documentElement.style.setProperty(
    "--textNavbar",
    initialState.culori.textNavbar
  )
  document.documentElement.style.setProperty("--text", initialState.culori.text)
  document.documentElement.style.setProperty(
    "--cardText",
    initialState.culori.cardText
  )
  document.documentElement.style.setProperty(
    "--boxShadow",
    initialState.culori.boxShadow
  )
  //console.log("INTRU AICI UNDE TREBUIE")
} else {
  localStorage.setItem("setari", JSON.stringify(initialState))
}

const setariReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SCHIMBA_SETARI":
      localStorage.setItem("setari", JSON.stringify(action.payload))
      return action.payload
    default:
      return state
  }
}

export default setariReducer

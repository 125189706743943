const angajatiReducer = (state = [], action) => {
  switch (action.type) {
    case "SCHIMBA_ANGAJATI":
      return action.payload
    default:
      return state
  }
}

export default angajatiReducer

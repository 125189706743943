import React from "react"
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"

export default function StatisticiVarsta(props) {
  const data = [
    {
      nume: "2010",
      Angajati: 4,
    },
    {
      nume: "2011",
      Angajati: 19,
    },
    {
      nume: "2012",
      Angajati: 20,
    },
    {
      nume: "2013",
      Angajati: 26,
    },
    {
      nume: "2014",
      Angajati: 21,
    },
    {
      nume: "2015",
      Angajati: 0,
    },
    {
      nume: "2016",
      Angajati: 31,
    },
    {
      nume: "2017",
      Angajati: 33,
    },
    {
      nume: "2018",
      Angajati: 20,
    },
    {
      nume: "2019",
      Angajati: 21,
    },
  ]
  return (
    <div className="StatisticiCifraAfaceri">
      <div className="TitluStatistica">Număr angajați</div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            left: -25,
            top: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="nume" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Angajati" fill="#8884d8" label={{ position: "top" }} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { schimbaAsigurari } from "../../Actions"
import moment from "moment"
import { CSSTransition } from "react-transition-group"
import axios from "axios"
import { Editor, EditorState, ContentState } from "draft-js"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LinkConfig from "../Config.js"
import MediaQuery from "react-responsive"

export default function RaportR(props) {
  const loginData = useSelector((state) => state.authReducer)
  const setari = useSelector((state) => state.setariReducer)
  const [row, setRow] = useState(props.date)
  const asigurari = useSelector((state) => state.asigurariReducer)
  const [update, setUpdate] = useState(0)
  const dispatch = useDispatch()
  const [indexAs, setIndexAs] = useState(0)

  useEffect(() => {
    //console.log(row.nume)
    setRow(props.date)
  }, [props.date])

  function schimba(e, nume) {
    //console.log("INTRA AICI IN ASTA")
    let ceva = row
    ceva[nume] = e.target.innerText
    //console.log(e.target.innerText)
    props.salveazaRow(props.index, ceva)
  }

  return (
    <div className="RaportRow">
      <MediaQuery minDeviceWidth={901}>
        <div
          className="raportRowCell"
          suppressContentEditableWarning={true}
          contentEditable={props.editeaza ? "true" : "false"}
          id="serie"
          onInput={(e) => {
            schimba(e, "serie")
          }}
          style={{ width: props.dimensiuniUlei.serie + "px" }}
        >
          {props.date.serie}
        </div>

        <div
          className="raportRowCell"
          suppressContentEditableWarning={true}
          contentEditable={props.editeaza ? "true" : "false"}
          id="piesa"
          onInput={(e) => {
            schimba(e, "piesa")
          }}
          style={{ width: props.dimensiuniUlei.piesa + "px" }}
        >
          {props.date.piesa}
        </div>

        <div
          className="raportRowCell"
          suppressContentEditableWarning={true}
          contentEditable={props.editeaza ? "true" : "false"}
          id="schimb"
          onInput={(e) => {
            schimba(e, "schimb")
          }}
          style={{ width: props.dimensiuniUlei.schimb + "px" }}
        >
          {props.date.schimb}
        </div>

        <div
          className="raportRowCell"
          suppressContentEditableWarning={true}
          contentEditable={props.editeaza ? "true" : "false"}
          id="km"
          onInput={(e) => {
            schimba(e, "km")
          }}
          style={{ width: props.dimensiuniUlei.km + "px" }}
        >
          {props.date.km}
        </div>

        <div
          className="raportRowCell"
          suppressContentEditableWarning={true}
          contentEditable={props.editeaza ? "true" : "false"}
          id="obs"
          onInput={(e) => {
            schimba(e, "obs")
          }}
          style={{ width: props.dimensiuniUlei.obs + "px" }}
        >
          {props.date.obs}
        </div>
        {props.date.km !== undefined &&
        props.date.km !== "" &&
        props.date.kmSchimbUlei !== undefined &&
        props.date.kmSchimbUlei !== "" ? (
          <div className="raportRowUleiCellDreapta">
            <span>
              {parseInt(props.date.km) + parseInt(props.date.kmSchimbUlei)}km
            </span>
          </div>
        ) : (
          ""
        )}
      </MediaQuery>

      <MediaQuery maxDeviceWidth={900}>
        {props.editeaza === false && props.date.serie === undefined ? (
          ""
        ) : (
          <div className="CellResponsive">
            <div className="CellTitluResponsive">Serie mașină</div>
            <div
              className="raportRowCell"
              suppressContentEditableWarning={true}
              contentEditable={props.editeaza ? "true" : "false"}
              id="serie"
              onInput={(e) => {
                schimba(e, "serie")
              }}
              style={{ width: props.dimensiuniUlei.serie + "px" }}
            >
              {props.date.serie}
            </div>
          </div>
        )}

        {props.editeaza === false && props.date.piesa === undefined ? (
          ""
        ) : (
          <div className="CellResponsive">
            <div className="CellTitluResponsive">Piesa schimbată</div>
            <div
              className="raportRowCell"
              suppressContentEditableWarning={true}
              contentEditable={props.editeaza ? "true" : "false"}
              id="piesa"
              onInput={(e) => {
                schimba(e, "piesa")
              }}
              style={{ width: props.dimensiuniUlei.piesa + "px" }}
            >
              {props.date.piesa}
            </div>
          </div>
        )}

        {props.editeaza === false && props.date.schimb === undefined ? (
          ""
        ) : (
          <div className="CellResponsive">
            <div className="CellTitluResponsive">Schimb ulei</div>
            <div
              className="raportRowCell"
              suppressContentEditableWarning={true}
              contentEditable={props.editeaza ? "true" : "false"}
              id="schimb"
              onInput={(e) => {
                schimba(e, "schimb")
              }}
              style={{ width: props.dimensiuniUlei.schimb + "px" }}
            >
              {props.date.schimb}
            </div>
          </div>
        )}

        {props.editeaza === false && props.date.km === undefined ? (
          ""
        ) : (
          <div className="CellResponsive">
            <div className="CellTitluResponsive">Km</div>
            <div
              className="raportRowCell"
              suppressContentEditableWarning={true}
              contentEditable={props.editeaza ? "true" : "false"}
              id="km"
              onInput={(e) => {
                schimba(e, "km")
              }}
              style={{ width: props.dimensiuniUlei.km + "px" }}
            >
              {props.date.km}
            </div>
          </div>
        )}

        {props.editeaza === false && props.date.obs === undefined ? (
          ""
        ) : (
          <div className="CellResponsive">
            <div className="CellTitluResponsive">Observații</div>
            <div
              className="raportRowCell"
              suppressContentEditableWarning={true}
              contentEditable={props.editeaza ? "true" : "false"}
              id="obs"
              onInput={(e) => {
                schimba(e, "obs")
              }}
              style={{ width: props.dimensiuniUlei.obs + "px" }}
            >
              {props.date.obs}
            </div>
          </div>
        )}
      </MediaQuery>
    </div>
  )
}

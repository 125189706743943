import React, { useEffect, useState } from "react"
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts"
import axios from "axios"
import LinkConfig from "../Config.js"
import { useSelector } from "react-redux"
import StatisticiVarsta from "./StatisticiVarsta"
import StatisticiSoferi from "./StatisticiSoferi"
import StatisticiAngajati from "./StatisticiAngajati"

const vw = Math.max(
  document.documentElement.clientWidth || 0,
  window.innerWidth || 0
)
const COLORS = ["#82C272", "#00A88F", "#0087AC", "#005FAA", "#323B81"]
const COLORS2 = [
  "#D8F3DC",
  "#B7E4C7",
  "#95D5B2",
  "#74C69D",
  "#52B788",
  "#40916C",
  "#2D6A4F",
  "#1B4332",
  "#081C15",
]
export default function Statistici(props) {
  const [personal, setPersonal] = useState(null)
  const [asigurari, setAsigurari] = useState(null)
  const loginData = useSelector((state) => state.authReducer)

  useEffect(() => {
    //PREIAU ANGAJATII
    axios
      .get(LinkConfig.link + "/angajati/", {
        headers: { Authorization: loginData.token },
      })
      .then((res) => {
        setPersonal(res.data)
        //console.log(res.data)
      })
      .catch((err) => {})
  }, [])
  const data = [
    {
      name: "Varsta 18-25",
      data1: 1,
    },
    {
      name: "Varsta 25-35",
      data1: 4,
    },
    {
      name: "Varsta 35-45",
      data1: 15,
    },
    {
      name: "Varsta 45-55",
      data1: 4,
    },
    {
      name: "Varsta 55-65",
      data1: 1,
    },
  ]
  const data2 = [
    {
      name: "Programator",
      data1: 1,
    },
    {
      name: "Jurist",
      data1: 1,
    },
    {
      name: "Logistica",
      data1: 3,
    },
    {
      name: "Contabil",
      data1: 1,
    },
    {
      name: "Necalificat",
      data1: 12,
    },
    {
      name: "Sofer",
      data1: 4,
    },
    {
      name: "Mecanic",
      data1: 2,
    },
    {
      name: "Sudor",
      data1: 2,
    },
    {
      name: "Sef",
      data1: 1,
    },
  ]
  return (
    <div className="ContainerStatistici">
      {personal === null || personal === undefined ? (
        <div className="SeIncarcaStatistici">
          <div>Se încarcă</div>
          <img src={require("../../Imagini/loading.gif")}></img>
        </div>
      ) : (
        <div className="MiniContainerStatistici">
          <StatisticiSoferi personal={personal}></StatisticiSoferi>
          <div className="ContainerStatistici2">
            <div className="TitluStatistica">Vârsta personal</div>
            <PieChart
              width={vw < 900 ? 250 : 350}
              height={vw < 900 ? 300 : 400}
              data={data}
            >
              <Legend />
              <Pie
                data={data}
                dataKey="data1"
                isAnimationActive={true}
                outerRadius={vw < 900 ? 80 : 120}
                fill="rgb(122,44,55)"
                label
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
          <div className="ContainerStatistici2">
            <div className="TitluStatistica">Locuri de muncă</div>
            <PieChart
              width={vw < 900 ? 250 : 350}
              height={vw < 900 ? 300 : 400}
              data={data}
              margin={{ top: 0 }}
            >
              <Legend />
              <Pie
                data={data2}
                dataKey="data1"
                isAnimationActive={true}
                outerRadius={vw < 900 ? 80 : 120}
                fill="rgb(122,44,55)"
                label
              >
                {data2.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS2[index % COLORS2.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
          <StatisticiVarsta />
          <StatisticiAngajati></StatisticiAngajati>
        </div>
      )}
    </div>
  )
}

import React from "react"

export default function personalCard(props) {
  return (
    <div
      className="personalCard"
      onClick={() => props.click(props.id, props.index)}
    >
      <img src={require("../../Imagini/siglaFirma.png")}></img>
      <div>{props.date.nume}</div>
      <div>{props.date.varsta} ani</div>
      <div>{props.date.alteNume}</div>
      <div>{props.date.functia}</div>
    </div>
  )
}

import React, { useState, useEffect } from "react"
import Toggle from "react-toggle"
import "react-toggle/style.css"
import { schimbaSetari } from "../../Actions/index.js"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

export default function SetariContainer(props) {
  const login = useSelector((state) => state.authReducer)
  const history = useHistory()
  useEffect(() => {
    //console.log("AM INTRAT IN APPP  ", login)
    if (!login.token) {
      history.push("/autentificare")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setari = useSelector((state) => state.setariReducer)
  const dispatch = useDispatch()
  const [update, setUpdate] = useState(-1)
  const [arataReseteazaPopup, setArataReseteazaPopup] = useState(false)

  function resetareSetari() {
    dispatch(
      schimbaSetari({
        modZi: true,
        modNoapte: false,
        textMarimeRaport: 2,
        textMarimeCalendar: 2,
        afisareNotificariRaport: true,
        timpNotificare: 30,
        numarKmUlei: 10000,
        dimensiuni: {
          masini: "100",
          programStabilit: "170",
          beneficiar: "100",
          programRelativ: "150",
          rest: "100",
          realizari: "100",
          numeSofer: "150",
        },
        dimensiuniUlei: {
          serie: "200",
          piesa: "300",
          schimb: "200",
          km: "150",
          obs: "150",
        },
        culori: {
          background: "rgb(0, 44, 61)",
          backgroundInchis: "rgb(255, 255, 255)",
          backgroundBody: "rgb(245, 245, 245)",
          cardBackground: "rgb(255, 255, 255)",
          textNavbar: "rgb(255, 255, 255)",
          text: "rgb(55, 55, 55)",
          cardText: "rgb(189, 189, 189)",
          boxShadow: "rgb(206, 206, 206)",
        },
      })
    )
  }
  function handleModNoapte() {
    //console.log(setari)
    let setariC = setari
    if (setari.modNoapte) {
      setariC.modZi = true
      setariC.modNoapte = false
      setariC.culori = {
        background: "rgb(0, 44, 61)",
        backgroundInchis: "rgb(255, 255, 255)",
        backgroundBody: "rgb(245, 245, 245)",
        cardBackground: "rgb(255, 255, 255)",
        textNavbar: "rgb(255, 255, 255)",
        text: "rgb(55, 55, 55)",
        cardText: "rgb(189, 189, 189)",
        boxShadow: "rgb(206, 206, 206)",
      }
    } else {
      setariC.modZi = false
      setariC.modNoapte = true
      setariC.culori = {
        background: "rgb(40, 40, 40)",
        backgroundInchis: "rgb(50, 50, 50)",
        backgroundBody: "rgb(30, 30, 30)",
        cardBackground: "rgb(50, 50, 50)",
        textNavbar: "rgb(255, 255, 255)",
        text: "rgb(255, 255, 255)",
        cardText: "rgb(255, 255, 255)",
        boxShadow: "rgb(10, 10, 10)",
      }
    }
    //console.log("MOD ZI  ", setariC.modZi)

    document.documentElement.style.setProperty(
      "--background",
      setariC.culori.background
    )
    document.documentElement.style.setProperty(
      "--backgroundInchis",
      setariC.culori.backgroundInchis
    )
    document.documentElement.style.setProperty(
      "--backgroundBody",
      setariC.culori.backgroundBody
    )
    document.documentElement.style.setProperty(
      "--cardBackground",
      setariC.culori.cardBackground
    )
    document.documentElement.style.setProperty(
      "--textNavbar",
      setariC.culori.textNavbar
    )
    document.documentElement.style.setProperty("--text", setariC.culori.text)
    document.documentElement.style.setProperty(
      "--cardText",
      setariC.culori.cardText
    )
    document.documentElement.style.setProperty(
      "--boxShadow",
      setariC.culori.boxShadow
    )
    dispatch(schimbaSetari(setariC))
    setUpdate(update + 1)
  }
  function handleAfisareNotificariRaport() {
    let setariC = setari
    if (setari.afisareNotificariRaport) setariC.afisareNotificariRaport = false
    else setariC.afisareNotificariRaport = true
    dispatch(schimbaSetari(setariC))
    setUpdate(update + 1)
  }

  function handleTimpNotificare(e) {
    let setariC = setari
    setariC[e.target.id] = e.target.value
    document.documentElement.style.setProperty(
      "--textMarimeRaport",
      setariC.textMarimeRaport + "vh"
    )
    document.documentElement.style.setProperty(
      "--textMarimeCalendar",
      setariC.textMarimeCalendar + "vh"
    )
    dispatch(schimbaSetari(setariC))
    setUpdate(update + 1)
  }
  return (
    <div className="containerSetari">
      <div
        className="spatePopup"
        style={{ display: arataReseteazaPopup ? "flex" : "none" }}
        onClick={() => {
          setArataReseteazaPopup(false)
        }}
      ></div>

      <div
        className="popUpStergeAsigurarea"
        style={{ display: arataReseteazaPopup ? "flex" : "none" }}
      >
        <div>Doriți să reveniți la setările inițiale?</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{
              cursor: "poinetr",
            }}
            onClick={() => {
              resetareSetari()
              setArataReseteazaPopup(false)
            }}
          >
            DA
          </span>
          <span
            style={{
              cursor: "poinetr",
            }}
            onClick={() => {
              setArataReseteazaPopup(false)
            }}
          >
            NU
          </span>
        </div>
      </div>

      <div>
        <div className="setariTitlu">Afișaj</div>
        <div className="setariLinie">
          <div>Mod intunecat</div>
          <Toggle
            checked={setari.modNoapte}
            onChange={handleModNoapte}
          ></Toggle>
        </div>
        <div className="setariLinie">
          <div>Mod zi</div>
          <Toggle checked={setari.modZi} onChange={handleModNoapte}></Toggle>
        </div>
      </div>
      <div>
        <div className="setariTitlu">Dimensiuni text</div>
        <div className="setariLinie">
          <div>Text raport</div>{" "}
          <input
            step="0.2"
            id="textMarimeRaport"
            type="number"
            min="1"
            value={setari.textMarimeRaport}
            onChange={handleTimpNotificare}
          ></input>
        </div>
        <div className="setariLinie">
          <div>Text calendar</div>{" "}
          <input
            step="0.2"
            id="textMarimeCalendar"
            type="number"
            min="01"
            value={setari.textMarimeCalendar}
            onChange={handleTimpNotificare}
          ></input>
        </div>
      </div>
      <div>
        <div className="setariTitlu">Notificări</div>
        <div className="setariLinie">
          <div>Afișare notificări raport</div>
          <Toggle
            checked={setari.afisareNotificariRaport}
            onChange={handleAfisareNotificariRaport}
          ></Toggle>
        </div>
        <div className="setariLinie">
          <div>Timp notificare asigurări (zile)</div>
          <input
            id="timpNotificare"
            type="number"
            min="0"
            value={setari.timpNotificare}
            onChange={handleTimpNotificare}
          ></input>
        </div>
      </div>
      <div></div>
      <button
        className="setariResetareButon"
        onClick={() => {
          setArataReseteazaPopup(true)
        }}
        value="RESETARE"
      >
        RESETARE
      </button>
    </div>
  )
}

import React, { useEffect, useState } from "react"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { schimbaAsigurari } from "../../Actions"
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Asigurare from "./Asigurare"
import AsigurariHeader from "./AsigurariHeader"
import LinkConfig from "../Config.js"
import MediaQuery from "react-responsive"

export default function Asigurari() {
  const loginData = useSelector((state) => state.authReducer)
  const [incarcat, setIncarcat] = useState(false)
  const [update, setUpdate] = useState(0)
  const [indexCurent, setIndexCurent] = useState(9999)
  const asigurari = useSelector((state) => state.asigurariReducer)
  const dimensiuni = {
    serieMasina: "300px",
    ITP: "220px",
    RCA: "220px",
    COPIECONFORMA: "220px",
    ROVINIETA: "220px",
    KM: "180px",
  }
  const dispatch = useDispatch()
  useEffect(() => {
    axios
      .get(LinkConfig.link + "/asigurari/preia/5f995c38f01ebf1570f70d12", {
        headers: { Authorization: loginData.token },
      })
      .then((res) => {
        dispatch(schimbaAsigurari(res.data.asigurari))
        //console.log(res.data.asigurari)
        setIncarcat(true)
      })
    //console.log(asigurari)
  }, [])
  function adaugaAsigurare() {
    //console.log(asigurari)
    let as = asigurari
    as.push({
      serieMasina: "",
      RCA: "",
      ITP: "",
      ROVINIETA: "",
      COPIECONFORMA: "",
      KM: "",
    })
    dispatch(schimbaAsigurari(as))
    setUpdate(update + 1)
  }

  function apasaIndex(index) {
    setIndexCurent(index)
  }

  function stergeAsigurare(index) {
    let as = asigurari
    //console.log("ASIGURARI INAINTE", index)
    as.splice(index, 1)
    dispatch(schimbaAsigurari(as))
    setUpdate(update + 1)
  }
  function salveaza() {
    let as = asigurari
    let asLength = as.length //pentru performanta
    for (let i = 0; i < asLength; i++) {
      if (!as[i].serieMasina.trim()) {
        as.splice(i, 1)
        i--
        asLength--
      }
    }

    dispatch(schimbaAsigurari(as))
    axios.post(
      LinkConfig.link + "/asigurari/update/5f995c38f01ebf1570f70d12",
      asigurari,
      {
        headers: { Authorization: loginData.token },
      }
    )
    setUpdate(update + 1)
  }

  const AsigurariDiv = asigurari.map((item, index) => {
    return (
      <Asigurare
        key={index}
        date={item}
        sterge={stergeAsigurare}
        index={index}
        apasaIndex={apasaIndex}
        indexCurent={indexCurent}
        dimensiuni={dimensiuni}
      ></Asigurare>
    )
  })
  return (
    <div className="AsigurareContainer">
      <MediaQuery minDeviceWidth={901}>
        <div className="AsigurariContainer">
          <AsigurariHeader dimensiuni={dimensiuni}></AsigurariHeader>
          {!incarcat ? (
            <div className="NimicGasit">
              <div>Se incarcă...</div>
              <img src={require("../../Imagini/loading.gif")}></img>
            </div>
          ) : (
            AsigurariDiv
          )}
        </div>

        <div className="containerAdaugaButon">
          <div onClick={adaugaAsigurare} className="adaugaButon">
            <div>
              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
            </div>
            <div className="text">Adauga serie</div>
          </div>
          <div onClick={salveaza} className="adaugaButon adaugaButonVerde">
            <div>
              {" "}
              <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
            </div>
            <div className="text">Salveaza</div>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxDeviceWidth={900}>
        <div className="AsigurariContainer">
          {!incarcat ? (
            <div className="NimicGasit">
              <div>Se incarcă...</div>
              <img src={require("../../Imagini/loading.gif")}></img>
            </div>
          ) : (
            AsigurariDiv
          )}
        </div>

        <div className="ButoaneJosRaport">
          <div onClick={adaugaAsigurare} className="adaugaButon">
            <div>
              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
            </div>
            <div className="text">Adauga serie</div>
          </div>
          <div onClick={salveaza} className="adaugaButon adaugaButonVerde">
            <div>
              {" "}
              <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
            </div>
            <div className="text">Salveaza</div>
          </div>
        </div>
      </MediaQuery>
    </div>
  )
}

import React, { useEffect } from "react"
import NavigationBar from "./Componente/App/NavigationBar"
import RaportCuRedux from "./Componente/App/RaportCuRedux.js"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

function App() {
  const login = useSelector((state) => state.authReducer)
  const history = useHistory()
  useEffect(() => {
    //console.log("AM INTRAT IN APPP  ", login)
    if (!login.token) {
      history.push("/autentificare")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="ContainerApp">
      <NavigationBar pagina="app"></NavigationBar>
      <RaportCuRedux></RaportCuRedux>
    </div>
  )
}

export default App

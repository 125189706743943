import React, { useEffect } from "react"
import NavigationBar from "./Componente/App/NavigationBar"
import StatisticiContainer from "./Componente/Statistici/Statistici.js"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

function Personal() {
  const login = useSelector((state) => state.authReducer)
  const history = useHistory()
  useEffect(() => {
    //console.log("AM INTRAT IN PERSONAL  ", login)
    if (!login.token) {
      history.push("/autentificare")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="ContainerApp">
      <NavigationBar pagina="statistici"></NavigationBar>
      <StatisticiContainer></StatisticiContainer>
    </div>
  )
}

export default Personal

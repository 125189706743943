const asigurariReducer = (state = [], action) => {
  switch (action.type) {
    case "SCHIMBA_ASIGURARI":
      return action.payload
    default:
      return state
  }
}

export default asigurariReducer

import React, { useState, useEffect } from "react"
import PersonalMap from "./PersonalMap"
import {
  faPlus,
  faTimes,
  faCheck,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { useSelector } from "react-redux"
import LinkConfig from "../Config.js"

export default function Personal(props) {
  const loginData = useSelector((state) => state.authReducer)
  const [personal, setPersonal] = useState([])
  const [adauga, setAdauga] = useState(false)
  const [editeaza, setEditeaza] = useState(false)
  const [update, setUpdate] = useState(-1)
  const [angajatCurent, setAngajatCurent] = useState({
    id: "",
    index: 0,
    angajat: {
      nume: "",
      alteNume: "",
      varsta: "",
      functia: "",
    },
  })
  const [angajat, setAngajat] = useState({
    nume: "",
    alteNume: "",
    varsta: "",
    functia: "",
  })
  useEffect(() => {
    axios
      .get(LinkConfig.link + "/angajati/", {
        headers: { Authorization: loginData.token },
      })
      .then((res) => {
        setPersonal(res.data)
        //console.log("intra aici adaugare angajat", res)
      })
      .catch((err) => {})
  }, [])

  useEffect(() => {
    console.log("ceva")
  }, [update])

  function onChange(e) {
    let st = angajat
    st[e.target.id] = e.target.value
    console.log(st)
    setAngajat(st)
  }
  function adaugaPersonal() {
    setAdauga(true)
  }
  function adaugaAngajat() {
    axios
      .post(LinkConfig.link + "/angajati/add", angajat, {
        headers: { Authorization: loginData.token },
      })
      .then((res) => {
        //console.log("intra aici adaugare angajat", res)
        axios
          .get(LinkConfig.link + "/angajati/preiaUltimul", {
            headers: { Authorization: loginData.token },
          })
          .then((res) => {
            console.log("INTRU AICIIIIIIIIIIIII", res.data[0])
            let ps = personal
            ps.push(res.data[0])
            setPersonal(ps)
            setUpdate(update + 1)
          })
          .catch((err) => console.log("ERORI", err.response.data.msg))
        setUpdate(update + 1)
      })
      .catch((err) => {
        console.log("ERORI", err.response.data.msg)
      })

    setAdauga(false)
  }

  function stergeAngajat() {
    console.log("intru sters ", angajatCurent.id, "index ", angajatCurent.index)
    let link = LinkConfig.link + "/angajati/sterge/" + angajatCurent.id
    axios
      .delete(link, {
        headers: { Authorization: loginData.token },
      })
      .then((res) => {
        let ps = personal
        ps.splice(angajatCurent.index, 1)
        setPersonal(ps)
        setUpdate(update + 1)
        console.log("sters")
      })
      .catch((err) => {
        console.log("ERORI", err.response.data.msg)
      })

    setEditeaza(false)
  }

  function editeazaAngajat(id, index) {
    let angCurent = angajatCurent
    angCurent.id = id
    angCurent.index = index
    angCurent.angajat.nume = personal[index].angajat.nume
    angCurent.angajat.varsta = personal[index].angajat.varsta
    angCurent.angajat.alteNume = personal[index].angajat.alteNume
    angCurent.angajat.functia = personal[index].angajat.functia
    setAngajatCurent(angCurent)
    setEditeaza(true)
  }

  function seteazaAngajat() {
    let angCurent = angajatCurent
    angCurent.angajat.nume = document.getElementById("numeEdit").value
    angCurent.angajat.varsta = document.getElementById("varstaEdit").value
    angCurent.angajat.alteNume = document.getElementById("alteNumeEdit").value
    angCurent.angajat.functia = document.getElementById("functiaEdit").value

    let personalCopie = personal
    personalCopie[angCurent.index].angajat = angCurent.angajat
    let link = LinkConfig.link + "/angajati/update/" + angajatCurent.id
    axios
      .post(link, angCurent.angajat, {
        headers: { Authorization: loginData.token },
      })
      .then((res) => {
        setPersonal(personalCopie)
      })
      .catch((err) => {
        console.log("ERORI", err.response.data.msg)
      })
    setEditeaza(false)
  }
  //const Personal = personal.map((item, id) => {
  //  return <PersonalCard></PersonalCard>
  //})
  return (
    <div className="personalContainer">
      <div className="adaugaPersonal" onClick={() => setAdauga(true)}>
        <div className="adaugaButon">
          <div>
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
          </div>
          <div className="text">Adauga personal</div>
        </div>
      </div>

      <div
        className="spatePopup"
        style={{ display: adauga || editeaza ? "flex" : "none" }}
        onClick={() => {
          setAdauga(false)
          setEditeaza(false)
        }}
      ></div>
      <div
        className="adaugaPersonalFixed"
        style={{ transform: adauga ? "scale(1)" : "scale(0)" }}
      >
        <FontAwesomeIcon
          onClick={() => setAdauga(false)}
          className="inchideButon"
          icon={faTimes}
        ></FontAwesomeIcon>
        <img src={require("../../Imagini/siglaFirma.png")}></img>

        <div>Nume</div>
        <input id="nume" type="text" onChange={onChange}></input>
        <div>Varsta</div>
        <input id="varsta" type="text" onChange={onChange}></input>
        <div>Alte nume</div>
        <input id="alteNume" type="text" onChange={onChange}></input>
        <div>Pozitie</div>
        <input id="functia" type="text" onChange={onChange}></input>
        <input
          className="adaugaImagine"
          id="file"
          type="file"
          name="photo"
          accept="image/*"
        />
        <div className="adaugaButon" onClick={adaugaAngajat}>
          <div>
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
          </div>
          <div className="text">Adauga</div>
        </div>
      </div>

      <div
        className="adaugaPersonalFixed"
        style={{ transform: editeaza ? "scale(1)" : "scale(0)" }}
      >
        <FontAwesomeIcon
          onClick={() => setEditeaza(false)}
          className="inchideButon"
          icon={faTimes}
        ></FontAwesomeIcon>
        <img src={require("../../Imagini/siglaFirma.png")}></img>
        <div>Nume</div>
        <input
          id="numeEdit"
          type="text"
          defaultValue={angajatCurent.angajat.nume}
        ></input>
        <div>Varsta</div>
        <input
          id="varstaEdit"
          type="text"
          defaultValue={angajatCurent.angajat.varsta}
        ></input>
        <div>Alte nume</div>
        <input
          id="alteNumeEdit"
          type="text"
          defaultValue={angajatCurent.angajat.alteNume}
        ></input>
        <div>Pozitie</div>
        <input
          id="functiaEdit"
          type="text"
          defaultValue={angajatCurent.angajat.functia}
        ></input>
        <input
          className="adaugaImagine"
          id="fileEdit"
          type="file"
          name="photo"
          accept="image/*"
        />
        <div className="butoanePersonalContainer">
          <div className="adaugaButon" onClick={seteazaAngajat}>
            <div>
              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
            </div>
            <div className="text">Salveaza</div>
          </div>
          <div className="adaugaButon" onClick={stergeAngajat}>
            <div>
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </div>
            <div className="text">Sterge</div>
          </div>
        </div>
      </div>

      <div className="personalCardContainer">
        {personal !== [] ? (
          <PersonalMap date={personal} editeaza={editeazaAngajat}></PersonalMap>
        ) : (
          <div className="NimicGasitPersonal">Se încarcă datele...</div>
        )}
      </div>
    </div>
  )
}

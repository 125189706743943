import React, { useEffect } from "react"
import SetariContainer from "./Componente/Setari/SetariContainer"
import NavigationBar from "./Componente/App/NavigationBar"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

export default function Setari(props) {
  const login = useSelector((state) => state.authReducer)
  const history = useHistory()
  useEffect(() => {
    if (!login.token) {
      history.push("/autentificare")
    }
    window.scrollTo(0, 0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="ContainerApp">
      <NavigationBar pagina="setari"></NavigationBar>
      <SetariContainer></SetariContainer>
    </div>
  )
}

import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function NavigationBarIcon(props) {
  return (
    <div className={props.date.stil}>
      <FontAwesomeIcon icon={props.date.icon} className="icon" />
      <div>{props.date.text}</div>
    </div>
  )
}

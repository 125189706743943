import React from "react"
import {
  LineChart,
  Line,
  Tooltip,
  AreaChart,
  XAxis,
  LabelList,
  ResponsiveContainer,
  Area,
  YAxis,
  Legend,
  CartesianGrid,
} from "recharts"

const vw = Math.max(
  document.documentElement.clientWidth || 0,
  window.innerWidth || 0
)
export default function StatisticiVarsta(props) {
  const data = [
    {
      nume: "2011",
      CifraAfaceri: 1298741,
      Profit: 648083,
    },
    {
      nume: "2011",
      CifraAfaceri: 1587089,
      Profit: -184344,
    },
    {
      nume: "2012",
      CifraAfaceri: 2265717,
      Profit: 124337,
    },
    {
      nume: "2013",
      CifraAfaceri: 1532896,
      Profit: -235739,
    },
    {
      nume: "2014",
      CifraAfaceri: 2032800,
      Profit: -147194,
    },
    {
      nume: "2015",
      CifraAfaceri: 3458472,
      Profit: -147194,
    },
    {
      nume: "2016",
      CifraAfaceri: 1719380,
      Profit: -147010,
    },
    {
      nume: "2017",
      CifraAfaceri: 2967348,
      Profit: 237520,
    },
    {
      nume: "2018",
      CifraAfaceri: 4062981,
      Profit: 826239,
    },
    {
      nume: "2019",
      CifraAfaceri: 4263862,
      Profit: 352052,
    },
  ]
  return (
    <div className="StatisticiCifraAfaceri">
      <div className="TitluStatistica">Cifră afaceri și profit</div>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={data}
          allowDataOverflow={true}
          margin={{ left: vw < 900 ? 10 : 20, top: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Area
            type="monotone"
            dataKey="CifraAfaceri"
            stroke="rgb(0 10 230)"
            fill="rgb(0 39 255)"
            fillOpacity="0.2"
          />
          <Area
            type="monotone"
            dataKey="Profit"
            stroke="rgb(53 219 60)"
            fill="rgb(53 239 60)"
          />
          <XAxis dataKey="nume"></XAxis> <YAxis dataKey="CifraAfaceri"></YAxis>
          <Tooltip></Tooltip>
          <Legend></Legend>
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

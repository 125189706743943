export const schimba = (item) => {
  return {
    type: "SCHIMBA",
    payload: item,
  }
}
export const schimbaUlei = (item) => {
  return {
    type: "SCHIMBA_ULEI",
    payload: item,
  }
}
export const schimbaAsigurari = (item) => {
  return {
    type: "SCHIMBA_ASIGURARI",
    payload: item,
  }
}
export const schimbaSetari = (item) => {
  return {
    type: "SCHIMBA_SETARI",
    payload: item,
  }
}
export const schimbaAngajati = (item) => {
  return {
    type: "SCHIMBA_ANGAJATI",
    payload: item,
  }
}

export const USER_LOADING = "USER_LOADING"
export const USER_LOADED = "USER_LOADED"
export const AUTH_ERROR = "AUTH_ERROR"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"
export const GET_ERRORS = "GET_ERRORS"
export const CLEAR_ERRORS = "CLEAR_ERRORS"

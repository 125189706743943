const raportReducer = (state = null, action) => {
    switch (action.type){
        case 'SCHIMBA':
            return action.payload;
        default:
            return state
    }

}

export default raportReducer;
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { schimbaAsigurari } from "../../Actions"
import moment from "moment"
import { CSSTransition } from "react-transition-group"
import ListaPersonal from "./ListaPersonal"
import axios from "axios"
import { Editor, EditorState, ContentState } from "draft-js"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LinkConfig from "../Config.js"
import MediaQuery from "react-responsive"

export default function RaportR(props) {
  const loginData = useSelector((state) => state.authReducer)
  const setari = useSelector((state) => state.setariReducer)
  const [row, setRow] = useState(props.date)
  const asigurari = useSelector((state) => state.asigurariReducer)
  const [update, setUpdate] = useState(0)
  const [arataListaPersonal, setArataListaPersonal] = useState(false)
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromText(props.date.numeSofer || "")
    )
  )
  const [asigurare, setAsigurare] = useState({
    expirat: false,
    urmeazaSaExpire: false,
    zilePanaLaExpirareITP: 0,
    zilePanaLaExpirareRCA: 0,
    ITPexpirat: false,
    RCAexpirat: false,
  })
  const dispatch = useDispatch()
  const [indexAs, setIndexAs] = useState(0)

  const editor = React.useRef(null)
  function focusEditor() {
    arataListaPersonalF()
    if (props.editeaza) editor.current.focus()
  }

  useEffect(() => {
    let ceva = row
    ceva.numeSofer = editorState.getCurrentContent().getPlainText()
    //console.log(e.target.innerText)
    props.salveazaRow(props.index, ceva)
  }, [editorState])

  useEffect(() => {
    //console.log("arataListaPersonal", props.date.personal)
  }, [arataListaPersonal])

  useEffect(() => {
    //console.log(row.nume)
    setRow(props.date)
  }, [props.date])

  function NumePersonal() {
    //console.log("DATE PERSONAL ", props.date.personal)
    if (props.date.personal !== ([] && undefined && null)) {
      const c = props.date.personal.map((item, ind) => {
        return (
          <div>
            <img src={require("../../Imagini/user.webp")}></img>
            <span>{item.nume}</span>
            {props.editeaza ? (
              <span onClick={(e) => stergeAngajatu(e, ind)}>
                <FontAwesomeIcon icon={faTimes} className="profilIcon" />
              </span>
            ) : (
              ""
            )}
          </div>
        )
      })
      return c
    } else return ""
    /*
    let z = 0
    if (x === []) {
      return <span></span>
    } else {
      for (let i = 0; i < x.length; i++) {
        if (props.date.serieMasina === x[i].serieMasina) {
          z = i
          break
        }
      }
      if (x[z] !== undefined)
        if (x[z].personal === []) {
          return <span></span>
        } else {
          const NumePersonalCopy = x[z].personal.map((item, ind) => {
            return <div>{item.nume}</div>
          })
          return NumePersonalCopy
        }
    }
  */
  }

  useEffect(() => {
    //console.log("INTRU IN ASIGURARI")
    let dataITP
    let dataAsigurare = asigurare
    if (setari.afisareNotificariRaport) {
      let asigLength = asigurari.length //pentru performanta
      for (let i = 0; i < asigLength; i++) {
        if (props.date.serieMasina === asigurari[i].serieMasina) {
          if (asigurari[i].ITP) {
            dataITP = moment(asigurari[i].ITP, "DD-MM-YYYY")
            if (dataITP < moment()) {
              dataAsigurare.expirat = true
              dataAsigurare.ITPexpirat = true
              setIndexAs(i)
            } else if (
              dataITP.subtract(setari.timpNotificare, "days") < moment()
            ) {
              dataAsigurare.zilePanaLaExpirareITP =
                dataITP.diff(moment(), "days") + setari.timpNotificare
              dataAsigurare.urmeazaSaExpire = true
              setIndexAs(i)
            }
          }
          if (asigurari[i].RCA) {
            dataITP = moment(asigurari[i].RCA, "DD-MM-YYYY")
            if (dataITP < moment()) {
              dataAsigurare.expirat = true
              dataAsigurare.RCAexpirat = true
              setIndexAs(i)
            }
            //moment().subtract(1, 'months').format('MMM YYYY');
            else if (
              dataITP.subtract(setari.timpNotificare, "days") < moment()
            ) {
              dataAsigurare.zilePanaLaExpirareRCA =
                dataITP.diff(moment(), "days") + setari.timpNotificare

              dataAsigurare.urmeazaSaExpire = true
              setIndexAs(i)
            }
          }
        }
      }
    }
    setAsigurare(dataAsigurare)
  }, [asigurari])
  function schimba(e, nume) {
    //console.log("INTRA AICI IN ASTA")
    let ceva = row
    ceva[nume] = e.target.innerText
    //console.log(e.target.innerText)
    props.salveazaRow(props.index, ceva)
  }

  function arataAsigurari() {
    //console.log("arata ASIGURARI ASIGURARI", indexAs)
    props.arataAsigurari({
      serieMasina: asigurari[indexAs].serieMasina,
      ITP: asigurari[indexAs].ITP,
      RCA: asigurari[indexAs].RCA,
      ITPexpirat: asigurare.ITPexpirat,
      RCAexpirat: asigurare.RCAexpirat,
      zilePanaLaExpirareITP: asigurare.zilePanaLaExpirareITP,
      zilePanaLaExpirareRCA: asigurare.zilePanaLaExpirareRCA,
      show: true,
    })
  }
  function arataListaPersonalF() {
    // console.log("Accesez lista personal")
    if (props.editeaza) {
      if (arataListaPersonal) setArataListaPersonal(false)
      else setArataListaPersonal(true)
    }
  }

  function stergeAngajatu(e, indexAngajat) {
    e.stopPropagation()
    //console.log(indexAngajat)
    if (props.editeaza) {
      let copieRow = row
      copieRow.personal.splice(indexAngajat, 1)
      setRow(copieRow)
      props.salveazaRow(props.index, copieRow)
      setUpdate(update + 1)
    }
    setArataListaPersonal(false)
  }
  function returneazaAngajatu(angajat) {
    //console.log("intra aici unde trebuie returneaza angajatu")
    setArataListaPersonal(false)
    //setUpdate(update + 1)
    //sterg numele sofer din row
    let rCopy = row
    rCopy.numeSofer = ""
    setRow(rCopy)
    //setez indexu din lista de asigurari corespunzand
    let index = null
    let asigLength = asigurari.length //pentru performanta
    for (let i = 0; i < asigLength; i++) {
      if (props.date.serieMasina === asigurari[i].serieMasina) {
        index = i
        break
      }
    }

    //adaug angajat in personalul din acest row nu in asigurari
    let personalRowCurent = props.date
    let existaPersonalNume = false
    personalRowCurent.personal.map((item, indx) => {
      if (angajat.nume === item.nume) {
        existaPersonalNume = true
      }
    })
    if (!existaPersonalNume) {
      personalRowCurent.personal.push(angajat)
    }
    props.salveazaRow(props.index, personalRowCurent)

    if (index !== null) {
      //fac copie asigurari
      let asig = asigurari

      /*
      //Resetare personal
      asig.map((item) => {
        item.personal = []
      })
      */
      //caut daca exista deja un angajat aici
      // console.log(angajat, " asig", asig)
      let sw = false
      //setez indexCurent pentru cand se adauga personal nou
      let indexCurent = asig[index].personal.length
      // console.log(indexCurent)

      //setez indexCurent la personalul existent
      asig[index].personal.map((item, id) => {
        if (item.nume === angajat.nume) {
          sw = true
          indexCurent = id
        }
      })

      if (sw) {
        if (existaPersonalNume === false)
          asig[index].personal[indexCurent].numar += 1
      } else {
        //adaug un angajat in lista
        asig[index].personal.push(angajat)
        asig[index].personal[indexCurent].numar = 0
      }
      //console.log(asig[index].personal[indexCurent].numar)
      dispatch(schimbaAsigurari(asig))
      axios
        .post(
          LinkConfig.link + "/asigurari/update/5f995c38f01ebf1570f70d12",
          asig,
          {
            headers: { Authorization: loginData.token },
          }
        )
        .then(() => {
          //console.log("a mers returneazaAngajatu")
        })
    }
    setUpdate(update + 1)
  }

  return (
    <div className="RaportRow">
      <MediaQuery minDeviceWidth={901}>
        <div className="RaportAsigurari">
          {!asigurare.urmeazaSaExpire ? (
            ""
          ) : (
            <CSSTransition
              appear={true}
              in={true}
              timeout={200}
              classNames="fadeFaraAsigurare"
            >
              <div
                onClick={arataAsigurari}
                className="faraAsigurare"
                style={{ background: "orange" }}
              >
                <span>!</span>
              </div>
            </CSSTransition>
          )}
          {!asigurare.expirat ? (
            ""
          ) : (
            <CSSTransition
              appear={true}
              in={true}
              timeout={200}
              classNames="fadeFaraAsigurare"
            >
              <div onClick={arataAsigurari} className="faraAsigurare">
                !
              </div>
            </CSSTransition>
          )}
        </div>
        <div
          className="raportRowCell"
          suppressContentEditableWarning={true}
          contentEditable={props.editeaza ? "true" : "false"}
          id="serieMasina"
          onInput={(e) => {
            schimba(e, "serieMasina")
          }}
          style={{ width: props.dimensiuni.masini + "px" }}
        >
          {props.date.serieMasina}
        </div>
        <div
          className="raportRowCell"
          suppressContentEditableWarning={true}
          contentEditable={props.editeaza ? "true" : "false"}
          id="programStabiliti"
          onInput={(e) => {
            schimba(e, "programStabiliti")
          }}
          style={{ width: props.dimensiuni.programStabilit + "px" }}
        >
          {props.date.programStabiliti}
        </div>
        <div
          className="raportRowCell"
          suppressContentEditableWarning={true}
          contentEditable={props.editeaza ? "true" : "false"}
          id="beneficiar"
          onInput={(e) => {
            schimba(e, "beneficiar")
          }}
          style={{ width: props.dimensiuni.beneficiar + "px" }}
        >
          {props.date.beneficiar}
        </div>
        <div
          className="raportRowCell"
          suppressContentEditableWarning={true}
          contentEditable={props.editeaza ? "true" : "false"}
          id="programRelativ"
          onInput={(e) => {
            schimba(e, "programRelativ")
          }}
          style={{ width: props.dimensiuni.programRelativ + "px" }}
        >
          {props.date.programRelativ}
        </div>
        <div
          className="raportRowCell"
          suppressContentEditableWarning={true}
          contentEditable={props.editeaza ? "true" : "false"}
          id="rest"
          onInput={(e) => {
            schimba(e, "rest")
          }}
          style={{ width: props.dimensiuni.rest + "px" }}
        >
          {props.date.rest}
        </div>
        <div
          className="raportRowCell"
          suppressContentEditableWarning={true}
          contentEditable={props.editeaza ? "true" : "false"}
          id="realizari"
          onInput={(e) => {
            schimba(e, "realizari")
          }}
          style={{ width: props.dimensiuni.realizari + "px" }}
        >
          {props.date.realizari}
        </div>
        <div
          className="raportRowCell"
          style={{ padding: "0" }}
          onClick={() => {
            focusEditor()
          }}
        >
          <div style={{ position: "relative" }}>
            {row.numeSofer === "" && props.editeaza === false ? (
              ""
            ) : (
              <Editor
                readOnly={!props.editeaza}
                ref={editor}
                className="raportRowCellNume"
                id="numeSofer"
                onInput={(e) => {
                  schimba(e, "numeSofer")
                }}
                style={{
                  width: props.dimensiuni.numeSofer + "px",
                  padding: "11px",
                }}
                editorState={editorState}
                onChange={setEditorState}
              ></Editor>
            )}
            {arataListaPersonal && props.editeaza ? (
              <ListaPersonal
                date={editorState.getCurrentContent().getPlainText() || ""}
                click={returneazaAngajatu}
                asigurari={asigurari}
                indexAsigurari={indexAs}
              ></ListaPersonal>
            ) : (
              ""
            )}
          </div>

          <div
            style={{
              width: props.dimensiuni.numeSofer + "px",
              display:
                row.personal === []
                  ? "none!important"
                  : "inline-flex!important",
            }}
            className="NumePersonalContainer"
          >
            {NumePersonal()}
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxDeviceWidth={900}>
        <div>
          {asigurare.zilePanaLaExpirareITP === 0 ? (
            ""
          ) : (
            <CSSTransition
              appear={true}
              in={true}
              timeout={200}
              classNames="fadeFaraAsigurare"
            >
              <div
                onClick={arataAsigurari}
                className="faraAsigurare"
                style={{ background: "orange" }}
              >
                <span>
                  {asigurare.zilePanaLaExpirareITP + " zile pana expira ITP"}
                </span>
              </div>
            </CSSTransition>
          )}

          {asigurare.zilePanaLaExpirareRCA === 0 ? (
            ""
          ) : (
            <CSSTransition
              appear={true}
              in={true}
              timeout={200}
              classNames="fadeFaraAsigurare"
            >
              <div
                onClick={arataAsigurari}
                className="faraAsigurare"
                style={{ background: "orange" }}
              >
                <span>
                  {asigurare.zilePanaLaExpirareRCA + " zile pana expira RCA"}
                </span>
              </div>
            </CSSTransition>
          )}

          {!asigurare.ITPexpirat ? (
            ""
          ) : (
            <CSSTransition
              appear={true}
              in={true}
              timeout={200}
              classNames="fadeFaraAsigurare"
            >
              <div onClick={arataAsigurari} className="faraAsigurare">
                ITP expirat
              </div>
            </CSSTransition>
          )}

          {!asigurare.RCAexpirat ? (
            ""
          ) : (
            <CSSTransition
              appear={true}
              in={true}
              timeout={200}
              classNames="fadeFaraAsigurare"
            >
              <div onClick={arataAsigurari} className="faraAsigurare">
                RCA expirat
              </div>
            </CSSTransition>
          )}
        </div>

        <div className="CellResponsive">
          <div className="CellTitluResponsive">Serie masina</div>
          <div
            className="raportRowCell"
            suppressContentEditableWarning={true}
            contentEditable={props.editeaza ? "true" : "false"}
            id="serieMasina"
            onInput={(e) => {
              schimba(e, "serieMasina")
            }}
            style={{ width: props.dimensiuni.masini + "px" }}
          >
            {props.date.serieMasina}
          </div>
        </div>

        {props.editeaza === false &&
        props.date.programStabiliti === undefined ? (
          ""
        ) : (
          <div className="CellResponsive">
            <div className="CellTitluResponsive">Program stabilit</div>
            <div
              className="raportRowCell"
              suppressContentEditableWarning={true}
              contentEditable={props.editeaza ? "true" : "false"}
              id="programStabiliti"
              onInput={(e) => {
                schimba(e, "programStabiliti")
              }}
              style={{ width: props.dimensiuni.programStabilit + "px" }}
            >
              {props.date.programStabiliti}
            </div>
          </div>
        )}

        {props.editeaza === false && props.date.beneficiar === undefined ? (
          ""
        ) : (
          <div className="CellResponsive">
            <div className="CellTitluResponsive">Beneficiar</div>
            <div
              className="raportRowCell"
              suppressContentEditableWarning={true}
              contentEditable={props.editeaza ? "true" : "false"}
              id="beneficiar"
              onInput={(e) => {
                schimba(e, "beneficiar")
              }}
              style={{ width: props.dimensiuni.beneficiar + "px" }}
            >
              {props.date.beneficiar}
            </div>
          </div>
        )}

        {props.editeaza === false && props.date.programRelativ === undefined ? (
          ""
        ) : (
          <div className="CellResponsive">
            <div className="CellTitluResponsive">Program relativ</div>
            <div
              className="raportRowCell"
              suppressContentEditableWarning={true}
              contentEditable={props.editeaza ? "true" : "false"}
              id="programRelativ"
              onInput={(e) => {
                schimba(e, "programRelativ")
              }}
              style={{ width: props.dimensiuni.programRelativ + "px" }}
            >
              {props.date.programRelativ}
            </div>
          </div>
        )}

        {props.editeaza === false && props.date.rest === undefined ? (
          ""
        ) : (
          <div className="CellResponsive">
            <div
              className="CellTitluResponsive"
              className="CellTitluResponsive"
            >
              Rest
            </div>
            <div
              className="raportRowCell"
              suppressContentEditableWarning={true}
              contentEditable={props.editeaza ? "true" : "false"}
              id="rest"
              onInput={(e) => {
                schimba(e, "rest")
              }}
              style={{ width: props.dimensiuni.rest + "px" }}
            >
              {props.date.rest}
            </div>
          </div>
        )}

        {props.editeaza === false && props.date.realizari === undefined ? (
          ""
        ) : (
          <div className="CellResponsive">
            <div className="CellTitluResponsive">Realizari</div>
            <div
              className="raportRowCell"
              suppressContentEditableWarning={true}
              contentEditable={props.editeaza ? "true" : "false"}
              id="realizari"
              onInput={(e) => {
                schimba(e, "realizari")
              }}
              style={{ width: props.dimensiuni.realizari + "px" }}
            >
              {props.date.realizari}
            </div>
          </div>
        )}

        <div className="CellResponsive">
          <div className="CellTitluResponsive">Nume sofer</div>
          <div
            className="raportRowCell"
            style={{ padding: "0" }}
            onClick={() => {
              focusEditor()
            }}
          >
            <div style={{ position: "relative" }}>
              {row.numeSofer === "" && props.editeaza === false ? (
                ""
              ) : (
                <Editor
                  readOnly={!props.editeaza}
                  ref={editor}
                  className="raportRowCellNume"
                  id="numeSofer"
                  onInput={(e) => {
                    schimba(e, "numeSofer")
                  }}
                  style={{
                    width: props.dimensiuni.numeSofer + "px",
                    padding: "11px",
                  }}
                  editorState={editorState}
                  onChange={setEditorState}
                ></Editor>
              )}
              {arataListaPersonal && props.editeaza ? (
                <ListaPersonal
                  date={editorState.getCurrentContent().getPlainText() || ""}
                  click={returneazaAngajatu}
                  asigurari={asigurari}
                  indexAsigurari={indexAs}
                ></ListaPersonal>
              ) : (
                ""
              )}
            </div>

            <div
              style={{
                width: props.dimensiuni.numeSofer + "px",
                display:
                  row.personal === []
                    ? "none!important"
                    : "inline-flex!important",
              }}
              className="NumePersonalContainer"
            >
              {NumePersonal()}
            </div>
          </div>
        </div>
      </MediaQuery>
    </div>
  )
}

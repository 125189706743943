import React, { useEffect, useState } from "react"
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts"
import axios from "axios"
import LinkConfig from "../Config.js"
import { useSelector } from "react-redux"

const COLORS = [
  "#4CC9F0",
  "#4895EF",
  "#4361EE",
  "#3F37C9",
  "#3A0CA3",
  "#480CA8",
  "#560BAD",
  "#7209B7",
  "#B5179E",
]
let data1 = []
const vw = Math.max(
  document.documentElement.clientWidth || 0,
  window.innerWidth || 0
)
export default function StatisticiSoferi(props) {
  const [asigurari, setAsigurari] = useState(null)
  const loginData = useSelector((state) => state.authReducer)
  const [sw, setSw] = useState(false)

  useEffect(() => {
    data1 = []
    //PREIAU ASIGURARILE
    axios
      .get(LinkConfig.link + "/asigurari/preia/5f995c38f01ebf1570f70d12", {
        headers: { Authorization: loginData.token },
      })
      .then((res) => {
        setAsigurari(res.data)
        //console.log(res.data.asigurari)
        //console.log(props.personal)

        data1 = []
        let personalLength = props.personal
        if (
          personalLength !== 0 &&
          props.personal !== null &&
          props.personal !== undefined
        ) {
          props.personal.map((item, index) => {
            data1.push({ name: item.angajat.nume, numar: 0 })
            res.data.asigurari.map((item2, index2) => {
              item2.personal.map((item3, index3) => {
                if (item.angajat.nume === item3.nume) {
                  data1[index].numar = data1[index].numar + item3.numar
                }
              })
            })
          })
          data1 = data1.filter((item) => {
            return item.numar !== 0
          })
          setSw(true)
        }

        //console.log(data1)
      })
  }, [])
  return (
    <div className="StatisticiSoferi">
      <div className="TitluStatistica">Zile conduse</div>

      {asigurari === null && sw === false ? (
        <div className="SeIncarcaStatistici" style={{ margin: "0" }}>
          <div>Se încarcă</div>
          <img src={require("../../Imagini/loading.gif")}></img>
        </div>
      ) : (
        <PieChart
          width={vw < 900 ? 250 : 350}
          height={vw < 900 ? 300 : 400}
          data={data1}
        >
          <Legend />
          <Pie
            data={data1}
            dataKey="numar"
            isAnimationActive={true}
            outerRadius={vw < 900 ? 80 : 120}
            fill="rgb(122,44,55)"
            label
          >
            {data1.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      )}
    </div>
  )
}

import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import "./responsive.css"
import App from "./App"
import Ulei from "./Ulei"
import Vehicule from "./Vehicule"
import Login from "./Login"
import Setari from "./Setari"
import Personal from "./Personal"
import Statistici from "./Statistici"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { createStore } from "redux"
import allReducers from "./Reducers/allReducers.js"
import { Provider } from "react-redux"
import { composeWithDevTools } from "redux-devtools-extension"
//import axios from "axios"
//axios.defaults.baseURL("https://popageorge.ro:60525")
//axios.defaults.baseURL("http://localhost:5000")

const store = createStore(allReducers, composeWithDevTools())

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/">
            <App />
          </Route>
          <Route exact path="/vehicule">
            <Vehicule />
          </Route>
          <Route exact path="/ulei">
            <Ulei />
          </Route>
          <Route exact path="/personal">
            <Personal />
          </Route>
          <Route exact path="/statistici">
            <Statistici />
          </Route>
          <Route exact path="/setari">
            <Setari />
          </Route>
          <Route exact path="/autentificare">
            <Login />
          </Route>
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

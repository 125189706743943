import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { schimbaAsigurari } from "../../Actions"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CSSTransition } from "react-transition-group"
import MediaQuery from "react-responsive"
import moment from "moment"

export default function Asigurare(props) {
  const setari = useSelector((state) => state.setariReducer)
  const asigurari = useSelector((state) => state.asigurariReducer)
  const dispatch = useDispatch()
  const [update, setUpdate] = useState(0)
  const [arataStergePopup, setArataStergePopup] = useState(false)

  function depasireData(data) {
    let dataAsigurare = moment(data, "DD-MM-YYYY")
    if (dataAsigurare < moment()) {
      return true
    } else return false
  }

  function maiSuntXzile(data) {
    let dataAsigurare = moment(data, "DD-MM-YYYY")
    if (dataAsigurare.subtract(setari.timpNotificare, "days") < moment()) {
      return dataAsigurare.diff(moment(), "days") + setari.timpNotificare
    } else return false
  }

  function schimbaCell(e, item) {
    let as = asigurari
    as[props.index][item] = e.target.value
    dispatch(schimbaAsigurari(as))
    setUpdate(update + 1)
  }
  return (
    <div
      className="AsigurareRow"
      onClick={() => {
        props.apasaIndex(props.index)
      }}
    >
      <MediaQuery minDeviceWidth={901}>
        <div
          className="spatePopup"
          style={{ display: arataStergePopup ? "flex" : "none" }}
          onClick={() => {
            setArataStergePopup(false)
          }}
        ></div>
        <div
          className="popUpStergeAsigurarea"
          style={{ display: arataStergePopup ? "flex" : "none" }}
        >
          <div>Doriti sa stergeti asigurarea {props.date.serMasina}</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <span
              onClick={() => {
                props.sterge(props.index)
              }}
            >
              DA
            </span>
            <span
              onClick={() => {
                setArataStergePopup(false)
              }}
            >
              NU
            </span>
          </div>
        </div>
        <input
          style={{ width: props.dimensiuni.serieMasina }}
          type="text"
          placeholder="serieMasina"
          value={props.date.serieMasina}
          onChange={(e) => {
            schimbaCell(e, "serieMasina")
          }}
          onClick={() => props.apasaIndex(props.index)}
        ></input>
        <input
          style={{
            width: props.dimensiuni.RCA,
            color: depasireData(props.date.RCA)
              ? "rgb(222,0,0)"
              : maiSuntXzile(props.date.RCA) != 0
              ? "rgb(211,145,11)"
              : "",
          }}
          type="text"
          placeholder="RCA"
          value={props.date.RCA}
          onChange={(e) => {
            schimbaCell(e, "RCA")
          }}
          onClick={() => props.apasaIndex(props.index)}
        ></input>
        <input
          style={{
            width: props.dimensiuni.ITP,
            color: depasireData(props.date.ITP)
              ? "rgb(222,0,0)"
              : maiSuntXzile(props.date.ITP) != 0
              ? "rgb(211,145,11)"
              : "",
          }}
          type="text"
          placeholder="ITP"
          value={props.date.ITP}
          onChange={(e) => {
            schimbaCell(e, "ITP")
          }}
          onClick={() => props.apasaIndex(props.index)}
        ></input>
        <input
          style={{
            width: props.dimensiuni.ROVINIETA,
            color: depasireData(props.date.ROVINIETA)
              ? "rgb(222,0,0)"
              : maiSuntXzile(props.date.ROVINIETA) != 0
              ? "rgb(211,145,11)"
              : "",
          }}
          type="text"
          placeholder="Rovinietă"
          value={props.date.ROVINIETA}
          onChange={(e) => {
            schimbaCell(e, "ROVINIETA")
          }}
          onClick={() => props.apasaIndex(props.index)}
        ></input>
        <input
          style={{
            width: props.dimensiuni.COPIECONFORMA,
            color: depasireData(props.date.COPIECONFORMA)
              ? "rgb(222,0,0)"
              : maiSuntXzile(props.date.COPIECONFORMA) != 0
              ? "rgb(211,145,11)"
              : "",
          }}
          type="text"
          placeholder="Copie Conformă"
          value={props.date.COPIECONFORMA}
          onChange={(e) => {
            schimbaCell(e, "COPIECONFORMA")
          }}
          onClick={() => props.apasaIndex(props.index)}
        ></input>

        <input
          style={{
            width: props.dimensiuni.KM,
          }}
          type="text"
          placeholder=""
          value={props.date.KM}
          onChange={(e) => {
            schimbaCell(e, "KM")
          }}
          onClick={() => props.apasaIndex(props.index)}
        ></input>

        {props.indexCurent === props.index ? (
          <CSSTransition
            appear={true}
            in={true}
            timeout={200}
            classNames="fadeTrash"
          >
            <div
              onClick={() => {
                setArataStergePopup(true)
              }}
              className="stergeAsigurare"
            >
              <FontAwesomeIcon icon={faTrash} />
            </div>
          </CSSTransition>
        ) : (
          ""
        )}
      </MediaQuery>

      <MediaQuery maxDeviceWidth={900}>
        <div
          className="spatePopup"
          style={{ display: arataStergePopup ? "flex" : "none" }}
          onClick={() => {
            setArataStergePopup(false)
          }}
        ></div>
        <div
          className="popUpStergeAsigurarea"
          style={{ display: arataStergePopup ? "flex" : "none" }}
        >
          <div>Doriti sa stergeti asigurarea {props.date.serMasina}</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <span
              onClick={() => {
                props.sterge(props.index)
              }}
            >
              DA
            </span>
            <span
              onClick={() => {
                setArataStergePopup(false)
              }}
            >
              NU
            </span>
          </div>
        </div>

        <div className="AsigurareRowResponsiv">
          <div>Serie</div>
          <input
            type="text"
            placeholder="serieMasina"
            value={props.date.serieMasina}
            onChange={(e) => {
              schimbaCell(e, "serieMasina")
            }}
            onClick={() => props.apasaIndex(props.index)}
          ></input>
        </div>
        <div className="AsigurareRowResponsiv">
          <div>RCA</div>
          <input
            style={{
              color: depasireData(props.date.RCA)
                ? "rgb(222,0,0)"
                : maiSuntXzile(props.date.RCA) != 0
                ? "rgb(211,145,11)"
                : "",
            }}
            type="text"
            placeholder="RCA"
            value={props.date.RCA}
            onChange={(e) => {
              schimbaCell(e, "RCA")
            }}
            onClick={() => props.apasaIndex(props.index)}
          ></input>
        </div>

        <div className="AsigurareRowResponsiv">
          <div>ITP</div>
          <input
            style={{
              color: depasireData(props.date.ITP)
                ? "rgb(222,0,0)"
                : maiSuntXzile(props.date.ITP) != 0
                ? "rgb(211,145,11)"
                : "",
            }}
            type="text"
            placeholder="ITP"
            value={props.date.ITP}
            onChange={(e) => {
              schimbaCell(e, "ITP")
            }}
            onClick={() => props.apasaIndex(props.index)}
          ></input>
        </div>

        <div className="AsigurareRowResponsiv">
          <div>Rovinietă</div>
          <input
            style={{
              color: depasireData(props.date.ROVINIETA)
                ? "rgb(222,0,0)"
                : maiSuntXzile(props.date.ROVINIETA) != 0
                ? "rgb(211,145,11)"
                : "",
            }}
            type="text"
            placeholder="Rovinietă"
            value={props.date.ROVINIETA}
            onChange={(e) => {
              schimbaCell(e, "ROVINIETA")
            }}
            onClick={() => props.apasaIndex(props.index)}
          ></input>
        </div>

        <div className="AsigurareRowResponsiv">
          <div>Copie conformă</div>
          <input
            style={{
              color: depasireData(props.date.COPIECONFORMA)
                ? "rgb(222,0,0)"
                : maiSuntXzile(props.date.COPIECONFORMA) != 0
                ? "rgb(211,145,11)"
                : "",
            }}
            type="text"
            placeholder="Copie Conformă"
            value={props.date.COPIECONFORMA}
            onChange={(e) => {
              schimbaCell(e, "COPIECONFORMA")
            }}
            onClick={() => props.apasaIndex(props.index)}
          ></input>
        </div>

        <div className="AsigurareRowResponsiv">
          <div>Km schimb ulei</div>
          <input
            type="text"
            placeholder="Km"
            value={props.date.KM}
            onChange={(e) => {
              schimbaCell(e, "KM")
            }}
            onClick={() => props.apasaIndex(props.index)}
          ></input>
        </div>
        {props.indexCurent === props.index ? (
          <CSSTransition
            appear={true}
            in={true}
            timeout={200}
            classNames="fadeTrash"
          >
            <div
              onClick={() => {
                setArataStergePopup(true)
              }}
              className="stergeAsigurare"
            >
              <FontAwesomeIcon icon={faTrash} />
              <span>Sterge asigurare</span>
            </div>
          </CSSTransition>
        ) : (
          ""
        )}
      </MediaQuery>
    </div>
  )
}

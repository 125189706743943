import React, { useState, useEffect } from "react"
import RaportR from "./RaportR"
import { useSelector } from "react-redux"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

let sw = 0
export default function RaportRow(props) {
  const setari = useSelector((state) => state.setariReducer)
  const [update, setUpdate] = useState(-1)
  const [raport, setRaport] = useState(props.date.row)
  const [arataAsigurare, setArataAsigurare] = useState({
    serieMasina: "eroare",
    ITP: "eroare",
    RCA: "eroare",
    ITPexpirat: false,
    RCAexpirat: false,
    zilePanaLaExpirareITP: 0,
    zilePanaLaExpirareRCA: 0,
    show: false,
  })
  useEffect(() => {
    //return props.salveaza(raport)
  }, [])

  useEffect(() => {
    setUpdate(update + 1)
    //console.log("INTRU UPDATEAZA USEFECT")
  }, [setari.dimensiuni])
  useEffect(() => {
    if (sw === 1) {
      props.inchideCalendar()
    }
  }, [sw])
  useEffect(() => {
    sw = 0
  }, [props])
  useEffect(() => {
    setRaport(props.date.row)
    //console.log("UPDATAT RAPORT ROW", props.date.row[0])
  }, [props.date.row])
  useEffect(() => {
    if (!props.editeaza) props.salveaza(raport)
  }, [props.editeaza])

  function salveazaRow(index, date) {
    let dateRaport = raport
    dateRaport.index = date
    setRaport(dateRaport)
  }

  function arataAsigurari(
    obiect = {
      serieMasina: "eroare",
      ITP: "eroare",
      RCA: "eroare",
      ITPexpirat: false,
      RCAexpirat: false,
      zilePanaLaExpirareITP: 0,
      zilePanaLaExpirareRCA: 0,
      show: true,
    }
  ) {
    setArataAsigurare(obiect)
    document
      .getElementById("arataAsigurariFixed")
      .classList.remove("arataAsigurariFixedInchis")
    document.getElementById("arataAsigurariFixed").style.visibility = "visible"
    document.getElementById("arataAsigurariFixed").style.transform = "scale(1)"
  }
  function ascundeAsigurari() {
    let arata = arataAsigurare
    arata.show = false
    setArataAsigurare(arata)
    setUpdate(update + 1)
    document
      .getElementById("arataAsigurariFixed")
      .classList.add("arataAsigurariFixedInchis")

    setTimeout(function () {
      document.getElementById("arataAsigurariFixed").style.visibility = "hidden"
    }, 500)
  }

  const Rows = raport.map((item, index) => {
    if (
      (item.programStabiliti !== undefined &&
        item.programStabiliti.length !== 0) ||
      (item.beneficiar !== undefined && item.beneficiar.length !== 0) ||
      (item.programRelativ !== undefined && item.programRelativ.length !== 0) ||
      (item.rest !== undefined && item.rest.length !== 0) ||
      (item.realizari !== undefined && item.realizari.length !== 0) ||
      (item.numeSofer !== undefined && item.numeSofer.length !== 0) ||
      (item.personal !== undefined && item.personal.length !== 0) ||
      props.editeaza
    ) {
      sw = 1
      return (
        <RaportR
          dimensiuni={setari.dimensiuni}
          key={index}
          date={item}
          index={index}
          editeaza={props.editeaza}
          salveazaRow={salveazaRow}
          arataAsigurari={arataAsigurari}
        ></RaportR>
      )
    }
  })
  return (
    <div>
      {Rows}
      {sw === 0 ? (
        <div className="NimicGasit">
          <div>Introduceți informații pentru ziua curentă</div>
        </div>
      ) : (
        <div></div>
      )}

      <div
        className="spatePopup"
        style={{ display: arataAsigurare.show ? "flex" : "none" }}
        onClick={() => {
          ascundeAsigurari()
        }}
      ></div>
      <div id="arataAsigurariFixed" className="arataAsigurariFixed">
        <div className="ascundeAsigurariFixed" onClick={ascundeAsigurari}>
          <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
        </div>
        <div>{arataAsigurare.serieMasina}</div>
        <div style={{ display: "inline-flex", flexDirection: "column" }}>
          <span
            style={{
              borderBottom: arataAsigurare.ITPexpirat
                ? "0.5vh solid red"
                : arataAsigurare.zilePanaLaExpirareITP !== 0
                ? "0.5vh solid orange"
                : "none",
            }}
          >
            ITP: {arataAsigurare.ITP}
          </span>
          <span>
            {arataAsigurare.zilePanaLaExpirareITP === 0
              ? ""
              : arataAsigurare.zilePanaLaExpirareITP + " zile ramase"}
          </span>
        </div>
        <div style={{ display: "inline-flex", flexDirection: "column" }}>
          <span
            style={{
              borderBottom: arataAsigurare.RCAexpirat
                ? "0.5vh solid red"
                : arataAsigurare.zilePanaLaExpirareRCA !== 0
                ? "0.5vh solid orange"
                : "none",
            }}
          >
            {" "}
            RCA: {arataAsigurare.RCA}
          </span>
          <span>
            {arataAsigurare.zilePanaLaExpirareRCA === 0
              ? ""
              : arataAsigurare.zilePanaLaExpirareRCA + " zile ramase"}
          </span>
        </div>
      </div>
    </div>
  )
}

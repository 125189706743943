import React, { useState } from "react"
import NavigationBarIcon from "./NavigationBarIcon"
import NavigationBarIconResponsiv from "./NavigationBarIconResponsiv"
import {
  faUser,
  faHome,
  faCar,
  faUserFriends,
  faSlidersH,
  faSignOutAlt,
  faChartPie,
  faBars,
  faOilCan,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import MediaQuery from "react-responsive"
export default function NavigationBar(props) {
  const [sw, setSw] = useState(true)
  const dispatch = useDispatch()
  function logOut() {
    //console.log("LOGOUT")
    dispatch({
      type: "LOGOUT_SUCCESS",
    })
  }
  return (
    <div className="NavigationBar">
      <MediaQuery maxDeviceWidth={900}>
        <div className="pozaProfil">
          <FontAwesomeIcon icon={faUser} className="profilIcon" />
        </div>
        <div className="iconSusResponsive">
          <FontAwesomeIcon
            icon={
              props.pagina === "app"
                ? faHome
                : props.pagina === "vehicule"
                ? faCar
                : props.pagina === "ulei"
                ? faOilCan
                : props.pagina === "personal"
                ? faUserFriends
                : props.pagina === "statistici"
                ? faChartPie
                : props.pagina === "setari"
                ? faSlidersH
                : faHome
            }
          />
        </div>
        <div className="NavigationBarResponsiveTitlu">
          {props.pagina === "app"
            ? "Raport"
            : props.pagina === "vehicule"
            ? "Asigurari"
            : props.pagina === "ulei"
            ? "Ulei"
            : props.pagina === "personal"
            ? "Personal"
            : props.pagina === "statistici"
            ? "Statistici"
            : props.pagina === "setari"
            ? "Setari"
            : ""}
        </div>
        <FontAwesomeIcon
          icon={faBars}
          className="NavigationBarMeniu"
          onClick={() => {
            sw ? setSw(false) : setSw(true)
          }}
        />
        <div className="meniuResponsiv" style={{ left: sw ? "100%" : "0" }}>
          <Link to="/" style={{ width: "100%" }}>
            <NavigationBarIconResponsiv
              date={{
                text: "Raport",
                icon: faHome,
                stil:
                  props.pagina === "app"
                    ? "NavigationBarIconActiv"
                    : "NavigationBarIcon",
              }}
            ></NavigationBarIconResponsiv>
          </Link>
          <Link to="/vehicule" style={{ width: "100%" }}>
            <NavigationBarIconResponsiv
              date={{
                text: "Asigurari",
                icon: faCar,
                stil:
                  props.pagina === "vehicule"
                    ? "NavigationBarIconActiv"
                    : "NavigationBarIcon",
              }}
            ></NavigationBarIconResponsiv>
          </Link>
          <Link to="/ulei" style={{ width: "100%" }}>
            <NavigationBarIconResponsiv
              date={{
                text: "Ulei",
                icon: faOilCan,
                stil:
                  props.pagina === "ulei"
                    ? "NavigationBarIconActiv"
                    : "NavigationBarIcon",
              }}
            ></NavigationBarIconResponsiv>
          </Link>
          <Link to="/personal" style={{ width: "100%" }}>
            <NavigationBarIconResponsiv
              date={{
                text: "Personal",
                icon: faUserFriends,
                stil:
                  props.pagina === "personal"
                    ? "NavigationBarIconActiv"
                    : "NavigationBarIcon",
              }}
            ></NavigationBarIconResponsiv>
          </Link>
          <Link to="/statistici" style={{ width: "100%" }}>
            <NavigationBarIconResponsiv
              date={{
                text: "Statistici",
                icon: faChartPie,
                stil:
                  props.pagina === "statistici"
                    ? "NavigationBarIconActiv"
                    : "NavigationBarIcon",
              }}
            ></NavigationBarIconResponsiv>
          </Link>
          <Link to="/setari" style={{ width: "100%" }}>
            <NavigationBarIconResponsiv
              date={{
                text: "Setari",
                icon: faSlidersH,
                stil:
                  props.pagina === "setari"
                    ? "NavigationBarIconActiv"
                    : "NavigationBarIcon",
              }}
            ></NavigationBarIconResponsiv>
          </Link>
          <Link onClick={logOut} to="/autentificare" style={{ width: "100%" }}>
            <NavigationBarIconResponsiv
              date={{
                text: "Log out",
                icon: faSignOutAlt,
                stil: "NavigationBarIcon",
              }}
            ></NavigationBarIconResponsiv>
          </Link>
        </div>
      </MediaQuery>

      <MediaQuery minDeviceWidth={901}>
        <div className="pozaProfil">
          <FontAwesomeIcon icon={faUser} className="profilIcon" />
        </div>

        <Link to="/" style={{ width: "100%" }}>
          <NavigationBarIcon
            date={{
              icon: faHome,
              stil:
                props.pagina === "app"
                  ? "NavigationBarIconActiv"
                  : "NavigationBarIcon",
            }}
          ></NavigationBarIcon>
        </Link>
        <Link to="/vehicule" style={{ width: "100%" }}>
          <NavigationBarIcon
            date={{
              icon: faCar,
              stil:
                props.pagina === "vehicule"
                  ? "NavigationBarIconActiv"
                  : "NavigationBarIcon",
            }}
          ></NavigationBarIcon>
        </Link>
        <Link to="/ulei" style={{ width: "100%" }}>
          <NavigationBarIcon
            date={{
              icon: faOilCan,
              stil:
                props.pagina === "ulei"
                  ? "NavigationBarIconActiv"
                  : "NavigationBarIcon",
            }}
          ></NavigationBarIcon>
        </Link>
        <Link to="/personal" style={{ width: "100%" }}>
          <NavigationBarIcon
            date={{
              icon: faUserFriends,
              stil:
                props.pagina === "personal"
                  ? "NavigationBarIconActiv"
                  : "NavigationBarIcon",
            }}
          ></NavigationBarIcon>
        </Link>
        <Link to="/statistici" style={{ width: "100%" }}>
          <NavigationBarIcon
            date={{
              icon: faChartPie,
              stil:
                props.pagina === "statistici"
                  ? "NavigationBarIconActiv"
                  : "NavigationBarIcon",
            }}
          ></NavigationBarIcon>
        </Link>
        <Link to="/setari" style={{ width: "100%" }}>
          <NavigationBarIcon
            date={{
              icon: faSlidersH,
              stil:
                props.pagina === "setari"
                  ? "NavigationBarIconActiv"
                  : "NavigationBarIcon",
            }}
          ></NavigationBarIcon>
        </Link>
        <Link onClick={logOut} to="/autentificare" style={{ width: "100%" }}>
          <NavigationBarIcon
            date={{ icon: faSignOutAlt, stil: "NavigationBarIcon" }}
          ></NavigationBarIcon>{" "}
        </Link>
      </MediaQuery>
    </div>
  )
}
